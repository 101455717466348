import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
  BsCurrencyRupee,
  BsFillEnvelopePlusFill,
} from "react-icons/bs";

import axios from "axios";
import { client } from "../config/Config";

const ListServiceTrans = (props) => {
  //function ListPatients(props) {
  const initialized = useRef(false);
  const navigate = useNavigate();

  var servicesTransArr = [
    {
      id: 1,
      date: "2023-08-23",
      study_id: "BS0001",
      study_name: "Ultra Scan",
      patient_name: "Muthusamy",
    },
    {
      id: 2,
      date: "2023-08-23",
      study_id: "BS0002",
      study_name: "Abdomen Scan",
      patient_name: "Chelladurai",
    },
    {
      id: 3,
      date: "2023-08-23",
      study_id: "BS0003",
      study_name: "Neck Scan",
      patient_name: "Anand",
    },
  ];

  const [servicesTransList, setServicesTransList] = useState(servicesTransArr);
  //navigate("/addUpdatePatient");

  const handleView = (e) => {
    e.preventDefault();
    console.log("Handle View service called : ", e);

    //const { id } = e.viewportElement;
    //console.log("Captured edit element name : ", name);
    //console.log("Captured edit element value : ", value);
    //console.log("Captured edit element id : ", id);

    //var currentRowId = id.substring(id.lastIndexOf("-") + 1);
    //var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    //console.log("Name of element : ", nameOfElement);
    //console.log("Current row id : ", currentRowId);

    //console.log("Currently selected row value : ", serviceList[currentRowId]);

    navigate("/addUpdateServiceTransV2", {
      state: [],
    });
  };

  const handleAdd = async (e) => {
    console.log("handleAdd clicked : ", e);

    navigate("/addUpdateServiceTransV2", {
      state: [],
    });
  };

  const handlePay = async (e) => {
    console.log("Handle pay clicked : ", e);
  };

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListPatients called ##########################"
      );

      initialized.current = true;
    }
  }, []);

 

  return (
    <Container>
      <Sidebar2 />
      <div className="container">
        <Row>
          <Col xs={6} md={6} sm={6}>
            <h2 className="text-left">Studies</h2>
          </Col>
          <Col xs={6} md={6} sm={6}>
            <Button
            variant="success"
              id="addUpdateId"
              className="float-end"
              onClick={(e) => handleAdd(e)}
            >
              New Study
            </Button>
          </Col>
        </Row>

        <Row style={{height:props.height}}><hr /></Row>
        <br></br>

        <table className="table table-bordered table-striped">
          <thead>
            <tr className="table-primary">
              <th style={{color: '#4d4d4d'}} key={0}>S.No</th>
              <th style={{color: '#4d4d4d'}}>Date</th>
              <th style={{color: '#4d4d4d'}}>Study Id</th>
              <th style={{color: '#4d4d4d'}}>Study name</th>
              <th style={{color: '#4d4d4d'}}>Patient</th>
              <th style={{color: '#4d4d4d'}}>Details</th>
              <th style={{color: '#4d4d4d'}}>Pay</th>
            </tr>
          </thead>
          <tbody>
            {servicesTransList.map((serviceTran, idx) => (
              <tr>
                <td key={idx}>{idx + 1}</td>
                <td>{serviceTran.date}</td>
                <td>{serviceTran.study_id}</td>
                <td>{serviceTran.study_name}</td>
                <td>{serviceTran.patient_name}</td>

                <td>
                  <BsCardText
                    key={`view-icon-service-key-${idx}`}
                    size={28}
                    style={{ color: "#0D6EFD", cursor: "pointer" }}
                    onClick={(e) => handleView(e)}
                    name={`view-icon-service-name-${idx}`}
                    id={`view-icon-service-id-${idx}`}
                  />
                </td>
                <td>
                  <BsCurrencyRupee
                    key={`pay-icon-service-key-${idx}`}
                    size={28}
                    style={{ color: "#0D6EFD", cursor: "pointer" }}
                    onClick={(e) => handlePay(e)}
                    name={`pay-icon-service-name-${idx}`}
                    id={`pay-icon-service-id-${idx}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <br></br>
      </div>
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListServiceTrans;
