import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Table,
  Accordion,
  Card,
  Badge
} from "react-bootstrap";
import React, { useRef } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import Sidebar2 from "./Sidebar2";
import { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-datepicker";

import { BsFileEarmarkPdf, BsBook } from "react-icons/bs";

import _ from "lodash";

//import "../Dashboard.css";
//import "../App.css";

//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap/dist/css/bootstrap.min.css";

//import 'react-tabs/style/react-tabs.css';

const AddUpdateServiceTransV3 = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  var patientArr = [
    {
      patientId: "BS0001",
      first_Name: "araya",
      mobile_Primary: "9942410747",
      age: 35,
      gender: "Male",
      address1: "2/262, ramana nagar",
      address2: "Avinashi",
      city: "Tiruppur",
      last_visit_time: "06-09-2023",
      category: "Scan",
      study: "Ultra Scan",
      bill_amount: 100.0,
      bill_type: "Paid",
    },
    {
      patientId: "BS0002",
      first_Name: "Ilango",
      mobile_Primary: "8870886958",
      age: 35,
      gender: "Male",
      address1: "Soolai",
      address2: "Avinashi",
      city: "Tiruppur",
      last_visit_time: "21-05-2023",
      category: "Lab",
      study: "Blood Test",
      bill_amount: 300.0,
      bill_type: "Free",
    },
    {
      patientId: "BS0003",
      first_Name: "Celcia",
      mobile_Primary: "9787453372",
      age: 23,
      gender: "Female",
      address1: "Sevur",
      address2: "Avinashi",
      city: "Tiruppur",
      last_visit_time: "18-02-2023",
      category: "Scan",
      study: "Neck Scan",
      bill_amount: 200.0,
      bill_type: "Paid",
      patient: "",
    },
  ];
  const dateTmp = new Date();
  dateTmp.setHours(0, 0, 0, 0);
  let intialValues = {
    first_name: "",
    last_name: "",
    mobile_primary: "",
    mobile_secondary: "",
    age: "",
    gender: "",
    address_line1: "",
    address_line2: "",
    district_id: 0,
    pincode: "",
    bloodpressure: "",
    pulse: "",
    temperature: "",
    studyDate: dateTmp,
  };

  const optionsDistrict = [
    { value: 1, label: "Coimbatore" },
    { value: 2, label: "Tiruppur" },
    { value: 3, label: "Erode" },
    { value: 4, label: "Karur" },
    { value: 5, label: "Namakkal" },
    { value: 6, label: "Ooty" },
    { value: 7, label: "Salem" },
  ];
  const optionsDoctor = [
    { value: 1, label: "Nikil Vishnu(PSG)" },
    { value: 2, label: "Kumar Ram(CNS)" },
    { value: 3, label: "Karthick Raja(ABC)" },
    { value: 4, label: "Anu Krishnan(KMCH)" },
    { value: 3, label: "Sukumar Kanna(SNS)" },
  ];
  const optionsStudy = [
    { value: 1, label: "Ultra Scan" },
    { value: 2, label: "Abdomen Scan" },
    { value: 3, label: "Neck Scan" },
  ];

  const [searchedPatientList, setSearchedPatientList] = useState(patientArr);

  const [key, setKey] = useState("visit-history");

  const [tabIndex, setTabIndex] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [districtIdSelect, setDistrictIdSelect] = useState({});
  const [file1, setFile1] = useState();
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [formValues, setFormValues] = useState(intialValues);
  const [searched, setSearched] = useState(false);
  const [patientSelected, setPatientSelected] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [studyIdSelect, setStudyIdSelect] = useState({});
  const [optDoctor, setOptDoctor] = useState(optionsDoctor);
  const [optStudy, setOptStudy] = useState(optionsStudy);
  const [doctorIdSelect, setDoctorIdSelect] = useState({});

  const [showSearchItems, setShowSearchItems] = useState(false);
  const [showAddItems, setShowAddItems] = useState(true);
  const teethSize = 32;
  useEffect(() => {
    console.log("-----------useEffect called ************");
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateStudyV3 called ##########################"
      );
      //console.log("PASSED VALUE LENGTH : ", state);
      /*
      if (state === null) {
        //console.log("############## state called : ", state);
        navigate("/listStudies");
      } else {
        console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);

        if (state.action === "view") {
          setFormValues(state.passed_element);

          const searchedElement = optionsDistrict.find(
            (ele) => ele.value === state.passed_element.district_id
          );
          console.log("searched element : ", searchedElement);
          setDistrictIdSelect(searchedElement);
        }
      }
      */
      /*
      if (state === null) {
        navigate("/listStudies");
      } else {
        console.log("PASSED STATE VALUE IN Study Add/Update page : ", state);

        setFormValues(state.passed_element);

        if (state.action === "add") {
          setIsReadOnly(false);
        }
      }
      */
      setIsReadOnly(false);
      initialized.current = true;
    }
  }, []);

  const handleRadioSelect = async (e) => {
    console.log("Handle readio select called : ", e);
  };
  var today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  var studyDateStr = `${date}-${month}-${year}`;
  const handleClickSave = async (e) => {
    console.log("Handle Click save called : ", e);
  };
  const toSqlDatetime = (inputDate) => {
    const date = new Date(inputDate);
    const dateWithOffest = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return dateWithOffest.toISOString().slice(0, 19).replace("T", " ");
  };
  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    var formattedDt = toSqlDatetime(e);

    setFormValues({ ...formValues, ["studyDate"]: formattedDt });

    console.log("Formatted str DC date : ", formattedDt);
  };
  const handleStudySelect = (e, data) => {
    console.log("handleStudySelect called ...", e);
    setStudyIdSelect(e);
  };
  const handleDoctorSelect = (e, data) => {
    console.log("handleDoctorSelect called ...", e);
    setDoctorIdSelect(e);
    setFormValues({ ...formValues, ["ref_doctorId"]: e.value });

    var DoctorIdInput = e.value;
    var DoctorNameInput = e.label;
    //getDistrictList(stateIdInput, stateNameInput);
  };

  const handleViewReport = async (e) => {
    console.log("Handle view report called : ", e);
  };
  const handleDistrictSelect = async (e) => {
    console.log("handle district id select called : ", e);

    setDistrictIdSelect(e);

    setFormValues({ ...formValues, ["district_id"]: e.value });
  };

  const handleFileChange = (e) => {
    console.log("handleFileChange clicked : ", e);
    console.log("selected files : ", e.target.files);
    console.log("selected files 1st: ", e.target.files[0].name);
    //setFile1(URL.createObjectURL(e.target.files[0]));
    setFile1(e.target.files[0].name);
    console.log("File1 content: ", file1);
    //window.open(URL.createObjectURL(e.target.files[0]));
    setSelectedDocs(Array.from(e.target.files));
  };

  const handleSearch = async (e) => {
    console.log("handle search clicked : ", e);
    setSearched(true);
  };
  const handleValueChange = (e) => {
    //e.preventDefault();

    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };

  const handleClinicalNote = async (e) => {
    console.log("handle clinical note clicked : ", e);
    setPatientSelected(true);
    setSearched(false);
    //setShowAddItems(false);
  };
  const handleOnChange = async (e) => {
    console.log("On change clicked : ", e);
    var tagId = e.target.id;
    var currentRowName = tagId.substring(tagId.lastIndexOf("-") + 1);
    var nameOfElement = tagId.substring(0, tagId.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);

    if (currentRowName === "searchpatient") {
      setShowSearchItems(true);
      setShowAddItems(false);
      setPatientSelected(false);
      setFormValues({
        ...formValues,
        ["patient"]: currentRowName,
      });
    } else if (currentRowName === "newpatient") {
      setShowAddItems(true);
      setShowSearchItems(false);
      setSearched(false);
      setPatientSelected(false);
      setFormValues({
        ...formValues,
        ["patient"]: currentRowName,
      });
    } else {
      setFormValues({
        ...formValues,
        ["gender"]: currentRowName.toUpperCase(),
      });
    }
  };

  const [currentActiveKey, setCurrentActiveKey] = useState("0");

  const toggleActiveKey = (key) => {
    console.log("toggleActiveKey called : ", key);
    setCurrentActiveKey(currentActiveKey === key ? null : key);
  };

  const [selecctedCheckBox, setSelectedCheckBoxes] = useState([]);
  const handleCheckBoxChange = async (e) => {
    console.log("handleCheckBoxChange called : ", e);
    console.log("handleCheckBoxChange called ID : ", e.target.id);
    console.log("handleCheckBoxChange called CHECKED : ", e.target.checked);
  };

  const handlePatient = async (e) => {
    console.log("handle patient called : ", e);

    navigate("/addUpdatePatient", {
      state: { passed_element: {}, action: "add" },
    });
  };
  const handleReset = async (e) => {
    console.log("Handle click Reset called : ", e);
  };
  const handleSave = async (e) => {
    console.log("Handle click Save called : ", e);
  };
  return (
    <>
      <Container>
        <Sidebar2 />
        <h3 style={{ color: "#4d4d4d" }}>Case Study</h3>
        <hr align="center"/>
        <br></br>

        <Form>
          <Row>
            <Col xs={2} md={2} sm={2} lg={2}>
              <div className="field">
                <Form.Label>Case Study for:</Form.Label>
              </div>
            </Col>
            <Col xs={7} md={7} sm={7} lg={7}>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  disabled={isReadOnly}
                  inline
                  label="New Patient"
                  name="patient"
                  type={"radio"}
                  checked={showAddItems}
                  id={`inline-radio-newpatient`}
                  onChange={(e) => handleOnChange(e)}
                />
                <Form.Check
                  disabled={isReadOnly}
                  inline
                  label="Existing Patient"
                  name="patient"
                  type={"radio"}
                  checked={showSearchItems}
                  id={`inline-radio-searchpatient`}
                  onChange={(e) => handleOnChange(e)}
                />
              </div>
            </Col>
          </Row>
          {showSearchItems && (
            <Row>
              <Col
                xs={10}
                sm={10}
                md={10}
                lg={10}
                className="float-end justify-content-center"
              >
                <div className="d-flex justify-content-center">
                  &nbsp;&nbsp;
                  <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="search-mobile"
                    name="searchMobile"
                    placeholder=" Existing Patient Mobile "
                  /></Form.Group>
                  &nbsp;&nbsp;
                  <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    id="search-name"
                    name="searchName"
                    placeholder="Existing Patient Name"
                  /></Form.Group>
                  &nbsp;&nbsp;
                  <Button variant="primary" onClick={handleSearch} className="h-25">
                    SEARCH
                  </Button>
                  &nbsp;&nbsp;
                  
                  <Button variant="secondary" className="h-25">CLEAR</Button>
                  &nbsp;&nbsp;
                </div>
              </Col>
            </Row>
          )}
        </Form>

        <br></br>

        <div className="ui-divider"></div>
        {showAddItems && !patientSelected && (
          <div className="ui-form">
            <Row>
            <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name</Form.Label>
                 <Form.Control
             
                    type="text"
                    name="first_name"
                    placeholder=" Name"
                    value={formValues.first_name}
                    onChange={(e) => handleValueChange(e)}
                    readOnly={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.first_name}</p>
              </Col>
              <Col xs={2} md={2} sm={2} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Age</Form.Label>
                 <Form.Control
            
                    type="number"
                    name="age"
                    className="mobNum"
                    placeholder="Age"
                    value={formValues.age}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.age}</p>
              </Col>
              
              <Col xs={2} md={2} sm={2} lg={2}>
              <Form.Group className="mb-3">
                <Form.Label>Gender</Form.Label>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check
                    disabled={isReadOnly}
                    inline
                    label="Male"
                    name="gender"
                    type={"radio"}
                    id={`inline-radio-male`}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <Form.Check
                    disabled={isReadOnly}
                    inline
                    label="Female"
                    name="gender"
                    type={"radio"}
                    id={`inline-radio-female`}
                    onChange={(e) => handleOnChange(e)}
                  />
                </div>
                </Form.Group>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required"> Mobile</Form.Label>
                 <Form.Control
             
                    type="number"
                    name="mobile_primary"
                    className="mobNum"
                    placeholder="Mobile"
                    value={formValues.mobile_primary}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                  />
               </Form.Group>
                <p className="error-msg">{formErrors.mobile_primary}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Alternative Mobile</Form.Label>
                 <Form.Control
                    type="number"
                    name="mobile_secondary"
                    className="mobNum"
                    placeholder="Alternative Mobile"
                    value={formValues.mobile_secondary}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                  />
                  </Form.Group>
               
                <p className="error-msg">{formErrors.mobile_secondary}</p>
              </Col>
            </Row>

          
           
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Street / Location</Form.Label>
                 <Form.Control
                    type="text"
                    name="address_line1"
                    placeholder="Street / Location"
                    value={formValues.address_line1}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                  />
               </Form.Group>
                <p className="error-msg">{formErrors.address_line1}</p>
              </Col>
              
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">City / Town</Form.Label>
                  <Form.Control
             
                    type="text"
                    name="address_line2"
                    placeholder="City / Town"
                    value={formValues.address_line2}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.address_line2}</p>
              </Col>
           
             

              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                <Form.Label>District</Form.Label>
               
                 
                  <Select
                    id="district"
                    name="district_id"
                    value={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    options={optionsDistrict}
                    defaultValue={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    onChange={handleDistrictSelect}
                    isDisabled={isReadOnly}
                  />
                
                </Form.Group>
                <p className="error-msg">{formErrors.district_id}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Pincode</Form.Label>
                  <Form.Control
             
                    type="text"
                    name="pincode"
                    placeholder="Pincode"
                    value={formValues.pincode}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                  />
              </Form.Group>
                <p className="error-msg">{formErrors.pincode}</p>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col
                xs={3}
                sm={3}
                md={3}
                lg={3}
                className="float-end justify-content-center"
              >
                <Button onClick={handleClinicalNote}>Enter Case Studies</Button>
              </Col>
            </Row>
          </div>
        )}
        {searched && showSearchItems && (
          <div>
            <Table striped bordered hover size="sm">
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading">...</th>
                  <th className="table-row-heading">Patient Id</th>
                  <th className="table-row-heading">Name</th>
                  <th className="table-row-heading">Mobile</th>
                  <th className="table-row-heading">City</th>
                  <th className="table-row-heading">Last Visit Date</th>
                  <th className="table-row-heading">Category</th>
                  <th className="table-row-heading">Study</th>
                  <th className="table-row-heading">Report</th>
                  <th className="table-row-heading">Bill Amount</th>
                </tr>
              </thead>
              <tbody>
                {searchedPatientList.map((patient, idx) => (
                  <tr key={`search-patient-key-${idx}`}>
                    <td>
                      <Form.Check
                        name={`check-row-name`}
                        id={`check-row-id-${idx}`}
                        type={"radio"}
                        onChange={handleRadioSelect}
                      ></Form.Check>
                    </td>
                    <td>{patient.patientId}</td>
                    <td>{patient.first_Name}</td>
                    <td>{patient.mobile_Primary}</td>
                    <td>{patient.city}</td>
                    <td>{patient.last_visit_time}</td>
                    <td>{patient.category}</td>
                    <td>{patient.study}</td>
                    <td>
                      <Button className="btn btn-sm" variant="danger">
                        <BsFileEarmarkPdf
                          key={`edit-btn-patient-key`}
                          size={24}
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleViewReport(e)}
                          name={`edit-icon-paBiSolidEditAlttient-name`}
                          id={`edit-icon-doctor-id`}
                        />
                      </Button>
                    </td>
                    <td>{patient.bill_amount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Row className="d-flex justify-content-center">
              <Col
                xs={3}
                sm={3}
                md={3}
                lg={3}
                className="float-end justify-content-center"
              >
                <Button onClick={handleClinicalNote}>Enter Case Study</Button>
              </Col>
            </Row>
          </div>
        )}

        {patientSelected && (
          <div>
            
                
                <Row>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h4 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Patient Details
                    </Badge>
                  </h4>
                </Col>
              </Row>
                <hr align="center" />

                <Form>
              <div
                style={{
                  backgroundColor: "#f6fcfe",
                  border: "2px",
                  borderRadius: "8px",
                  paddingLeft: "10px",
                }}
              >

                <div className="ui-divider"></div>
                <div className="ui-form">
                  <Row>
                    <Col xs={1} md={1} sm={1} lg={1}>
                      <div className="field">
                        <Form.Label>Patient Id:</Form.Label>
                      </div>
                    </Col>
                    <Col xs={3} md={3} sm={3} lg={3}>
                      <div className="field">
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={handlePatient}
                        >
                          BS0002
                        </p>
                      </div>
                    </Col>

                    <Col xs={1} md={1} sm={1} lg={1}>
                      <div className="field">
                        <Form.Label>Age:</Form.Label>
                      </div>
                    </Col>
                    <Col xs={3} md={3} sm={3} lg={3}>
                      <div className="field">
                        <p>25</p>
                      </div>
                    </Col>
                    <Col xs={1} md={1} sm={1} lg={1}>
                      <Form.Label>Name:</Form.Label>
                    </Col>
                    <Col xs={3} md={3} sm={3} lg={3}>
                      <div className="field">
                        <p>Ilango</p>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={1} md={1} sm={1} lg={1}>
                      <Form.Label>Mobile:</Form.Label>
                    </Col>
                    <Col xs={3} md={3} sm={3} lg={3}>
                      <div className="field">
                        <p>9078654321</p>
                      </div>
                    </Col>

                    <Col xs={1} md={1} sm={1} lg={1}>
                      <Form.Label>Address1:</Form.Label>
                    </Col>
                    <Col xs={3} md={3} sm={3} lg={3}>
                      <div className="field">
                        <p>No.23,Gandhi Nagar</p>
                      </div>
                    </Col>
                    <Col xs={1} md={1} sm={1} lg={1}>
                      <Form.Label>Address2:</Form.Label>
                    </Col>
                    <Col xs={3} md={3} sm={3} lg={3}>
                      <div className="field">
                        <p>Avinashi</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} md={1} sm={1} lg={1}>
                      <Form.Label>District: </Form.Label>
                    </Col>

                    <Col xs={3} md={3} sm={3} lg={3}>
                      <div className="field">
                        <p>Tiruppur</p>
                      </div>
                    </Col>

                    <Col xs={1} md={1} sm={1} lg={1}>
                      <Form.Label>Pincode:</Form.Label>
                    </Col>
                    <Col xs={3} md={3} sm={3} lg={3}>
                      <div className="field">
                        <p>641654</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <br></br>

              <Row>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Visit History</Accordion.Header>
                    <Accordion.Body>
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr className="table-primary">
                            <th className="table-row-heading">Last Visit Date</th>
                            <th className="table-row-heading">Category</th>
                            <th className="table-row-heading">Study</th>
                            <th className="table-row-heading">Report</th>
                            <th className="table-row-heading">Bill Type</th>
                            <th className="table-row-heading">Bill Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {searchedPatientList.map((patient, idx) => (
                            <tr key={`search-patient-key-${idx}`}>
                              <td>{patient.last_visit_time}</td>
                              <td>{patient.category}</td>
                              <td>{patient.study}</td>
                              <td>
                                <Button className="btn btn-sm" variant="danger">
                                  <BsFileEarmarkPdf
                                    key={`edit-btn-patient-key`}
                                    size={24}
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => handleViewReport(e)}
                                    name={`edit-icon-paBiSolidEditAlttient-name`}
                                    id={`edit-icon-doctor-id`}
                                  />
                                </Button>
                              </td>
                              <td>{patient.bill_type}</td>
                              <td>{patient.bill_amount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <br></br>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Row>
              <br></br>
              <Row>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h4 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Study Details
                    </Badge>
                  </h4>
                </Col>
              </Row>
              
              <hr align = "center"/>

              <div className="ui-divider"></div>

              <div className="ui-form">
                <Row>
               
            <Col xs={3} md={3} sm={3} lg={3}>
                  
            <Form.Group className="mb-3" >
             <Form.Label> Date</Form.Label>
                <Form.Control
                  type="date"
                  id="date"
                  name="date"
                  placeholder=" Date"
                  value={formValues.date}
                  onChange={onChangeDatePicker}
                  readOnly={isReadOnly}
                  
                />
              </Form.Group>
              <p className="error-msg">{formErrors.date}</p>
            </Col>
                   
                      

                  
                  <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Ref</Form.Label>
                    <Select
                      id="ref_doctor"
                      name="ref_doctorId"
                      value={{
                        value: doctorIdSelect.value,
                        label: doctorIdSelect.label,
                      }}
                      options={optionsDoctor}
                      defaultValue={{
                        value: doctorIdSelect.value,
                        label: doctorIdSelect.label,
                      }}
                      onChange={handleDoctorSelect}
                      isDisabled={isReadOnly}
                    />
                    </Form.Group>
                    <p className="error-msg">{formErrors.ref_doctorId}</p>
                  </Col>
                  
                </Row>
                <Row>
                 

                  <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Category</Form.Label>&nbsp;
                    <div key={`inline-radio`} className="mb-3">
                      <Form.Check
                        inline
                        label="SCAN"
                        key={`inline-radio`}
                        name="category_mode"
                        type="radio"
                        id={`inline-radio-scan`}
                        checked="true"
                      />
                      <Form.Check
                        inline
                        label="LAB"
                        key={`inline-radio`}
                        name="category_mode"
                        type="radio"
                        id={`inline-radio-lab`}
                      />
                      </div>
                   
                    </Form.Group>

                    <p className="error-msg">{formErrors.category}</p>
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>Study</Form.Label>
                    <Select
                      id="study"
                      name="studyId"
                      value={{
                        value: studyIdSelect.value,
                        label: studyIdSelect.label,
                      }}
                      options={optionsStudy}
                      defaultValue={{
                        value: studyIdSelect.value,
                        label: studyIdSelect.label,
                      }}
                      onChange={handleStudySelect}
                      isDisabled={isReadOnly}
                    />
                    </Form.Group>
                    <p className="error-msg">{formErrors.studyId}</p>
                  </Col>
                 

                  
                  <Col xs={3} md={3} sm={3} lg={3}>
                 <Form.Group className="mb-3">
                  <Form.Label className="field required">Charges</Form.Label>
                  <Form.Control
                 
                      type="text"
                      name="charges"
                      placeholder="Charges"
                      value={formValues.charges}
                      onChange={handleValueChange}
                      readOnly={isReadOnly}
                    />
                    </Form.Group>

                    <p className="error-msg">{formErrors.charges}</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} md={3} sm={3}>
                    <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Report</Form.Label>
                      <Form.Control type="file" onChange={handleFileChange} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h4 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Billing
                    </Badge>
                  </h4>
                </Col>
              </Row>
                
                <hr align="center" />
               
                <Row>
                 
                  <Col xs={3} md={3} sm={3}>
                 
                  <Form.Group className="mb-3">
                  <Form.Label>Type</Form.Label>&nbsp;
                  <div key={`inline-radio`} className="mb-3">  
                    <Form.Check
                      inline
                      key={`radio-key-bill_type`}
                      id={`radio_id-bill-type`}
                      name="bill"
                      label="PAID"
                      type="radio"
                      checked={true}
                    ></Form.Check>
                    <Form.Check
                      inline
                      key={`radio-key-bill_type`}
                      id={`radio_id-bill-type`}
                      name="bill"
                      label="FREE"
                      type="radio"
                      checked={true}
                    ></Form.Check>
                    </div>
                    </Form.Group>
                   
                  </Col>
                </Row>
                <br></br>
                <Row>
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Actual Amount</Form.Label>
                  <Form.Control
              
                    type="text"
                    id="actual-amount-id"
                    name="actualAmount"
                    placeholder="Actual amount"
                    value={formValues.actualAmount}
                    onChange={(e) => handleValueChange(e)}
                  />
                  </Form.Group>
                </Col>
                </Row>
                 
                <Row>
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Bill Amount</Form.Label>
                  <Form.Control
              
                    type="text"
                    id="bill-amount-id"
                    name="billAmount"
                    placeholder="Bill amount"
                    value={formValues.billAmount}
                    onChange={(e) => handleValueChange(e)}
                  />
                  </Form.Group>
                </Col>
                 
                </Row>
                
                <Row>
                  <Col xs={3} md={3} sm={3}>
                  <Form.Group className="mb-3">
                    <Form.Label> Collected Amount: </Form.Label>
                    <Form.Control
                     type="text"
                     id="collected-amount-id"
                     name="collectedAmount"
                     placeholder="Collected amount"
                     value={formValues.collectedAmount}
                     onChange={(e) => handleValueChange(e)}
                   />
                   </Form.Group>
                 </Col>
                 
                </Row>
                <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      //disabled={submitAction ? true: false}
                    >
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>

              </div>
            </Form>
          </div>
        )}
      </Container>
    </>
  );
};

export default AddUpdateServiceTransV3;
