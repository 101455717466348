import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Table,
  File,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import {
  BsFillPenFill,
  BsFillTrashFill,
  BsFillArrowLeftCircleFill,
  BsArrowLeftCircle,
} from "react-icons/bs";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const AddUpdateServiceTransV2 = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Study Add/Update page : ", state);
  var studyArr = [
    {
      SNo: 1,
      patientId: "BS0001",
      patientName: "Raj",
      age: 30,
      city: "Perunthurai",
      checkedState: false,
    },
    {
      SNo: 2,
      patientId: "BS0002",
      patientName: "Arul",
      age: 25,
      city: "Dharapuram",
      checkedState: false,
    },
    {
      SNo: 3,
      patientId: "BS0003",
      patientName: "Joel",
      age: 40,
      city: "Avinashi",
      checkedState: false,
    },
  ];

  let initialItems = [];
  const optionsDistrict = [
    { value: 1, label: "Coimbatore" },
    { value: 2, label: "Tiruppur" },
    { value: 3, label: "Erode" },
    { value: 4, label: "Karur" },
    { value: 5, label: "Namakkal" },
    { value: 6, label: "Ooty" },
    { value: 7, label: "Salem" },
  ];
  const optionsDoctor = [
    { value: 1, label: "Nikil Vishnu(PSG)" },
    { value: 2, label: "Kumar Ram(CNS)" },
    { value: 3, label: "Karthick Raja(ABC)" },
    { value: 4, label: "Anu Krishnan(KMCH)" },
    { value: 3, label: "Sukumar Kanna(SNS)" },
  ];
  const optionsStudy = [
    { value: 1, label: "Ultra Scan" },
    { value: 2, label: "Abdomen Scan" },
    { value: 3, label: "Neck Scan" },
  ];

  const intialValues = {
    patientId: "",
    age: "",
    patientName: "",
    address1: "",
    address2: "",
    pincode: "",
    mobileNumber: "",
    ref_doctorId: "",
    charges: "",
    id: 0,
    districtId: 0,
    doctorId: "",
    studyDate: new Date(),
    radio_id: "0",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);
  var validateErr = false;

  const intialValuesSearch = {
    searchMobile: "",
  };
  const [formValuesSearch, setFormValuesSearch] = useState(intialValuesSearch);
  const [formErrorsSearch, setFormErrorsSearch] = useState({});
  const [validateErrorSearch, setValidateErrorSearch] = useState(false);
  var validateErrSearch = false;
  const [isSubmitSearch, setIsSubmitSearch] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);

  const [studyList, setStudyList] = useState(studyArr);

  const [selectedIdx, setSelectedIdx] = useState();
  const [districtIdSelect, setDistrictIdSelect] = useState({});
  const [doctorIdSelect, setDoctorIdSelect] = useState({});
  const [studyIdSelect, setStudyIdSelect] = useState({});
  const [optDist, setOptDist] = useState(optionsDistrict);
  const [optDoctor, setOptDoctor] = useState(optionsDoctor);
  const [optStudy, setOptStudy] = useState(optionsStudy);

  const [searchSuccess, setSearchSuccess] = useState(false);
  const [patientSelected, setPatientSelected] = useState(false);

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateserviceTrans called ##########################"
      );
      /*
      if (state === null) {
        navigate("/listServiceTrans");
      } else {
        console.log(
          "PASSED STATE VALUE IN ServiceTrans Add/Update page : ",
          state
        );
      }
      */
      initialized.current = true;
    }
    console.log("File1 content: ", file1);
  }, []);

  const handleValueChangeSearch = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value SEARCH : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name SEARCH : ", name);
    console.log("Captured input element value SEARCH : ", value);
    setFormValuesSearch({ ...formValuesSearch, [name]: value });

    //console.log("Clicked data : ", data);
  };

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.patientName) {
      errors.patientName = "Patient name is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.studyId) {
      errors.studyId = "study type selection is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.ref_doctorId) {
      errors.ref_doctorId = "Doctor name is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.patientId) {
      errors.patientId = "Patient Id is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.age) {
      errors.age = "Age name is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.mobileNumber) {
      errors.mobileNumber = "Mobile number is required";
      validateErr = true;
      setValidateError(true);
    }

    if (values.mobileNumber.length != 10) {
      errors.mobileNumber = "Mobile number should be 10 digits";
      validateErr = true;
      setValidateError(true);
    }

    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);
    setDistrictIdSelect(e);

    var DistrictIdInput = e.value;
    var DistrictNameInput = e.label;
    //getDistrictList(stateIdInput, stateNameInput);
  };
  const handleDoctorSelect = (e, data) => {
    console.log("handleDoctorSelect called ...", e);
    setDoctorIdSelect(e);
    setFormValues({ ...formValues, ["ref_doctorId"]: e.value });

    var DoctorIdInput = e.value;
    var DoctorNameInput = e.label;
    //getDistrictList(stateIdInput, stateNameInput);
  };
  const handleStudySelect = (e, data) => {
    console.log("handleStudySelect called ...", e);
    setStudyIdSelect(e);

    setFormValues({ ...formValues, ["studyId"]: e.value });

    var studyIdInput = e.value;
    var studyNameInput = e.label;
    //getDistrictList(stateIdInput, stateNameInput);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ",
      validateError
    );
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      console.log("LINE ITEMS : ", items);
      console.log("Form submit called ...");

      console.log("patient Name : ", formValues["name"]);
    }
  };

  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit doctor called : ", e);
    setIsReadOnly(false);
  };

  const handleDelete = async (e) => {
    console.log("Handle delete doctor called : ", e);
  };

  const handleSave = async (e) => {
    console.log("Handle Click save called : ", e);
  };
  const handleReset = async (e) => {
    console.log("Handle click Reset called : ", e);
    setSearchSuccess(false);
    setPatientSelected(false);
  };

  const validateSearch = (values) => {
    console.log("validateErr before start processing SEARCH : ", validateErr);
    console.log("Validate called SEARCH ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.searchMobile) {
      errors.searchMobile = "Search Mobile number is required";
      validateErrSearch = true;
      setValidateErrorSearch(true);
    }

    if (values.searchMobile.length != 10) {
      errors.searchMobile = "Search Mobile number should be 10 digits";
      validateErrSearch = true;
      setValidateErrorSearch(true);
    }

    console.log("BEFORE RETURN validate errors ...", validateErrorSearch);
    console.log("VALIDATE ERROR inside validate func : ", validateErrSearch);
    return errors;
  };

  const handleSearch = async (e) => {
    console.log("Handle Click search called : ", e);

    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErrSearch = false;
    setFormErrorsSearch(validateSearch(formValuesSearch));
    setIsSubmitSearch(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ",
      validateErrorSearch
    );

    console.log("^^^^^^^^^^^^ IS submit search : ", isSubmitSearch);

    setSearchSuccess(true);
  };
  const handleClearSearch = async (e) => {
    e.preventDefault();
    console.log("Handle click  Clear Search called : ", e);
    setSearchSuccess(false);
    setPatientSelected(false);
  };

  const studyRowClick = async (e, id) => {
    e.preventDefault();
    console.log("Study row clicked : ", e);
    console.log("Clicked at : ", id.idx);

    //setFormValues({ ...formValues, ["checkStateRowIdx"]: parseInt(id.idx) });
    //console.log("FormValues row id: ", formValues["checkStateRowIdx"]);

    //setFormValues({ ...formValues, ["checkStateRowIdx"]: 1 });

    var idTmp = e.target.id;
    console.log("Selected id : ", idTmp);
    var currentId = idTmp.substring(idTmp.lastIndexOf("-") + 1);
    var nameOfElement = idTmp.substring(0, idTmp.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    setFormValues({ ...formValues, [nameOfElement]: parseInt(id.idx) });

    console.log("FormValues row id: ", formValues[nameOfElement]);
  };

  var today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  var studyDateStr = `${date}-${month}-${year}`;
  const handleClickSave = async (e) => {
    console.log("Handle Click save called : ", e);
  };

  /*
  const handleRadioOnChange = async (e) => {
    console.log("handle radio on changed called : ", e);

    var idTmp = e.target.id;
    console.log("Selected id : ", idTmp);
    var currentId = idTmp.substring(idTmp.lastIndexOf("-") + 1);
    var nameOfElement = idTmp.substring(0, idTmp.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    console.log("FormValues row id: ", formValues["checkStateRowIdx"]);

    setFormValues({ ...formValues, ["checkStateRowIdx"]: currentId });
  };
  */
  const [file1, setFile1] = useState();
  const [selectedDocs, setSelectedDocs] = useState([]);
  const handleFileChange = (e) => {
    console.log("handleFileChange clicked : ", e);
    console.log("selected files : ", e.target.files);
    console.log("selected files 1st: ", e.target.files[0].name);
    //setFile1(URL.createObjectURL(e.target.files[0]));
    setFile1(e.target.files[0].name);
    console.log("File1 content: ", file1);
    //window.open(URL.createObjectURL(e.target.files[0]));
    setSelectedDocs(Array.from(e.target.files));
  };

  const MyLoadingRenderer = ({ document, fileName }) => {
    const fileText = fileName || document?.fileType || "";
    console.log("fileText : ", fileText);

    if (fileText) {
      return <div>Loading Renderer ({fileText})...</div>;
    }

    return <div>Loading Renderer...</div>;
  };

  const handleNewStudy = async (e) => {
    console.log("handle new study clicked : ", e);
    setPatientSelected(true);
    setSearchSuccess(false);
  };

  return (
    <>
      <Container>
        <Sidebar2 />
        <Row>
          <Col xs={1} md={1} sm={1} lg={1}>
            <h3>Study</h3>
          </Col>
          <Col
            xs={11}
            md={11}
            sm={11}
            lg={11}
            className="float-end justify-content-end"
          >
            <div className="d-flex justify-content-end">
              <Button variant="warning" className="btn btn-warning btn-md">
                <BsArrowLeftCircle
                  key={`back-btn-patient-key`}
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleBack(e)}
                  name={`back-icon-patient-name`}
                  id={`edit-icon-patient-id`}
                />
              </Button>
              &nbsp;&nbsp;
              <Button variant="success" className="btn btn-success btn-md">
                <BsFillPenFill
                  key={`edit-btn-patient-key`}
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleEdit(e)}
                  name={`edit-icon-paBiSolidEditAlttient-name`}
                  id={`edit-icon-doctor-id`}
                />
              </Button>
              &nbsp;&nbsp;
              {/*<Button variant="danger" className="btn btn-danger btn-md">
                <BsFillTrashFill
                  key={`delete-btn-patient-key`}
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleDelete(e)}
                  name={`delete-icon-patient-name`}
                  id={`delete-icon-patient-id`}
                />
  </Button>*/}
            </div>
          </Col>
        </Row>
        <br></br>
        <Row>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Col xs={2} md={2} sm={2} lg={2}>
            <h4>Patients</h4>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2}>
            <input
              type="number"
              name="mobile_number"
              className="mobNum"
              id="search-mobile-id"
              placeholder="Mobile to search"
              value={formValues.mobile_number}
              onChange={(e) => handleValueChange(e)}
            />
            &nbsp;&nbsp;
            <p className="error-msg">{formErrors.mobile_number}</p>
          </Col>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Col xs={3} md={3} sm={3} lg={3}>
            <input
              type="text"
              name="first_name"
              id="search-name-id"
              placeholder="Name to search"
              value={formValues.first_name}
              onChange={(e) => handleValueChange(e)}
            />
            <p className="error-msg">{formErrors.first_name}</p>
          </Col>
          <Col xs={4} md={4} sm={4} lg={4}>
            &nbsp;&nbsp;
            <Button
              id="btn-search-id"
              name="btn-search-name"
              onClick={handleSearch}
            >
              Search
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="secondary"
              id="btn-search-id"
              name="btn-search-name"
              onClick={handleClearSearch}
            >
              Clear Search
            </Button>
          </Col>
        </Row>
        <Row style={{ height: props.height }}>
          <hr />
        </Row>
        <br></br>

        {searchSuccess && (
          <div className="ui-form">
            <Table table-bordered table-striped>
              <thead>
                <tr>
                  <th>...</th>
                  <th key={0}>S.No</th>
                  <th>Patient Id</th>
                  <th>Patient Name</th>
                  <th>Age</th>
                  <th>City</th>
                </tr>
              </thead>
              <tbody>
                {studyList.map((study, idx) => (
                  <tr
                    key={`study-tr-key-${idx}`}
                    onClick={(e, id = { idx }) => studyRowClick(e, id)}
                    id={`study-tr-id-${idx}`}
                    name={`study-tr-name-${idx}`}
                    age={`study-tr-age-${idx}`}
                    city={`study-tr-city-${idx}`}
                  >
                    <Form.Check
                      key={`radio-key-${idx}`}
                      id={`radio_id-${idx}`}
                      name="study"
                      type="radio"
                      checked={true}
                    ></Form.Check>
                    <td key={idx}>{idx + 1}</td>
                    <td>{study.patientId}</td>
                    <td>{study.patientName}</td>
                    <td>{study.age}</td>
                    <td>{study.city}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Row className="justify-content-center">
              <Col xs={4} sm={4} md={4} lg={4}>
                <Button variant="primary" onClick={handleNewStudy}>
                  Enter Study details
                </Button>
              </Col>
            </Row>
          </div>
        )}
        {patientSelected && (
          <div className="container">
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} md={12} sm={12} lg={12}>
                  <h3>Patients Details</h3>
                </Col>
              </Row>
              <br></br>
              <div className="ui-divider"></div>
              <div className="ui-form">
                <Row>
                  <Col xs={1} md={1} sm={1} lg={1}>
                    <div className="field">
                      <label>Patient Id:</label>
                    </div>
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3}>
                    <div className="field">
                      <p>BS0002</p>
                    </div>
                  </Col>

                  <Col xs={1} md={1} sm={1} lg={1}>
                    <div className="field">
                      <label>Age:</label>
                    </div>
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3}>
                    <div className="field">
                      <p>25</p>
                    </div>
                  </Col>
                  <Col xs={1} md={1} sm={1} lg={1}>
                    <label>Name:</label>
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3}>
                    <div className="field">
                      <p>Arul</p>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={1} md={1} sm={1} lg={1}>
                    <label>Mobile:</label>
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3}>
                    <div className="field">
                      <p>9078654321</p>
                    </div>
                  </Col>

                  <Col xs={1} md={1} sm={1} lg={1}>
                    <label>Address1:</label>
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3}>
                    <div className="field">
                      <p>No.23,Gandhi Nagar</p>
                    </div>
                  </Col>
                  <Col xs={1} md={1} sm={1} lg={1}>
                    <label>Address2:&nbsp;&nbsp;</label>
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3}>
                    <div className="field">
                      <p>Avinashi</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={1} md={1} sm={1} lg={1}>
                    <label>District: </label>
                  </Col>

                  <Col xs={3} md={3} sm={3} lg={3}>
                    <div className="field">
                      <p>Tiruppur</p>
                    </div>
                  </Col>

                  <Col xs={1} md={1} sm={1} lg={1}>
                    <label>Pincode:&nbsp;&nbsp;</label>
                  </Col>
                  <Col xs={3} md={3} sm={3} lg={3}>
                    <div className="field">
                      <p>641654</p>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <h3> Study Details </h3>
                </Row>

                <div className="ui-divider"></div>

                <div className="ui-form">
                  <Row>
                    <Col xs={1} md={1} sm={1} lg={1}>
                      <label>Date:&nbsp;&nbsp;</label>
                    </Col>
                    <Col xs={3} md={3} sm={3} lg={3}>
                      <p>{studyDateStr}</p>
                    </Col>
                    <Col xs={1} md={1} sm={1} lg={1}>
                      <label>Study: &nbsp;&nbsp;</label>
                    </Col>

                    <Col xs={3} md={3} sm={3} lg={3}>
                      <Select
                        id="study"
                        name="studyId"
                        value={{
                          value: studyIdSelect.value,
                          label: studyIdSelect.label,
                        }}
                        options={optionsStudy}
                        defaultValue={{
                          value: studyIdSelect.value,
                          label: studyIdSelect.label,
                        }}
                        onChange={handleStudySelect}
                        isDisabled={isReadOnly}
                      />
                      <p className="error-msg">{formErrors.studyId}</p>
                    </Col>

                    <Col xs={1} md={1} sm={1} lg={1}>
                      <label>Ref:&nbsp;&nbsp;</label>
                    </Col>

                    <Col xs={3} md={3} sm={3} lg={3}>
                      <Select
                        id="ref_doctor"
                        name="ref_doctorId"
                        value={{
                          value: doctorIdSelect.value,
                          label: doctorIdSelect.label,
                        }}
                        options={optionsDoctor}
                        defaultValue={{
                          value: doctorIdSelect.value,
                          label: doctorIdSelect.label,
                        }}
                        onChange={handleDoctorSelect}
                        isDisabled={isReadOnly}
                      />
                      <p className="error-msg">{formErrors.ref_doctorId}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} md={1} sm={1} lg={1}>
                      <label>Payment: </label>
                    </Col>

                    <Col xs={3} md={3} sm={3} lg={3}>
                      <div key={`inline-radio`} className="mb-3">
                        <Form.Check
                          inline
                          label="PAID"
                          name="payment_mode"
                          type="radio"
                          id={`inline-radio-paid`}
                          checked="true"
                        />
                        <Form.Check
                          inline
                          label="FREE"
                          name="payment_mode"
                          type="radio"
                          id={`inline-radio-free`}
                        />
                      </div>

                      <p className="error-msg">{formErrors.payment}</p>
                    </Col>
                    <Col xs={1} md={1} sm={1} lg={1}>
                      <label>Charges:</label>
                    </Col>

                    <Col xs={3} md={3} sm={3} lg={3}>
                      <input
                        type="text"
                        name="charges"
                        placeholder="Charges"
                        value={formValues.charges}
                        onChange={handleValueChange}
                        readOnly={isReadOnly}
                      />

                      <p className="error-msg">{formErrors.charges}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} md={1} sm={1} lg={1}>
                      <label>Report:</label>
                    </Col>
                    <Col xs={3} md={3} sm={3}>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Control type="file" onChange={handleFileChange} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} md={1} sm={1} lg={1}></Col>
                    <Col xs={3} md={3} sm={3}>
                      <div>
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Control type="file" />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} md={1} sm={1} lg={1}></Col>
                    <Col xs={3} md={3} sm={3}>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Control type="file" />
                      </Form.Group>
                    </Col>
                  </Row>

                  <br></br>

                  <Row className="flex float-end">
                    <Col xs={5} md={5} sm={5} lg={5}>
                      <Button
                        type="submit"
                        id="save"
                        onClick={(e) => handleSave(e)}
                      >
                        Save
                      </Button>
                    </Col>

                    <Col xs={7} md={7} sm={7} lg={7}>
                      <Button
                        variant="secondary"
                        id="Reset"
                        onClick={(e) => handleReset(e)}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Container>
    </>
  );
};

export default AddUpdateServiceTransV2;
