import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import {
  BsFillPenFill,
  BsFillTrashFill,
  BsFillArrowLeftCircleFill,
  BsArrowLeftCircle,
} from "react-icons/bs";

import DateTimePicker from "react-datetime-picker";

const AddUpdateServiceTrans = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);

  let initialItems = [];

  const optionsDoctor = [
    { value: 1, label: "Abi" },
    { value: 2, label: "John" },
    { value: 3, label: "Ram" },
  ];

  const optionsPatient = [
    { value: 1, label: "Raya" },
    { value: 2, label: "Rajamohan" },
    { value: 3, label: "Karthikeyan" },
  ];

  const optionsStudy = [
    { value: 1, label: "Ultra Scan" },
    { value: 2, label: "Abdomen Scan" },
    { value: 3, label: "Neck Scan" },
  ];

  const intialValues = {
    id: 0,
    doctorId: 0,
    patientId: 0,
    studyDate: new Date(),
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);

  const [doctorIdSelect, setDoctorIdSelect] = useState({});
  const [patientIdSelect, setPatientIdSelect] = useState({});
  const [studyIdSelect, setStudyIdSelect] = useState({});
  var validateErr = false;

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateServiceTrans called ##########################"
      );
      /*
      if (state === null) {
        navigate("/listServiceTrans");
      } else {
        console.log(
          "PASSED STATE VALUE IN ServiceTrans Add/Update page : ",
          state
        );
      }
      */
      //console.log("PASSED STATE VALUE IN Service Add/Update page : ", state);
      initialized.current = true;
    }
  }, []);

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.patient) {
      errors.patient = "Patient name is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.study) {
      errors.study = "Study name is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.ref_doctor) {
      errors.ref_doctor = "Doctor name is required";
      validateErr = true;
      setValidateError(true);
    }

    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const handleDoctorSelect = (e, data) => {
    console.log("handleDoctorSelect called ...", e);
    setDoctorIdSelect(e);

    var DoctorIdInput = e.value;
    var DoctorNameInput = e.label;
    //getDistrictList(stateIdInput, stateNameInput);
  };

  const handlePatientSelect = (e, data) => {
    console.log("handlePatientSelect called ...", e);
    setPatientIdSelect(e);

    var patientIdInput = e.value;
    var patientNameInput = e.label;
    //getDistrictList(stateIdInput, stateNameInput);
  };

  const handleStudySelect = (e, data) => {
    console.log("handleStudySelect called ...", e);
    setStudyIdSelect(e);

    var studyIdInput = e.value;
    var studyNameInput = e.label;
    //getDistrictList(stateIdInput, stateNameInput);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ",
      validateError
    );
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      console.log("LINE ITEMS : ", items);
      console.log("Form submit called ...");

      console.log("Patient Name : ", formValues["name"]);
    }
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit doctor called : ", e);
    setIsReadOnly(false);
  };

  const handleDelete = async (e) => {
    console.log("Handle delete doctor called : ", e);
  };

  const handleSave = async (e) => {
    console.log("Handle Click save called : ", e);
  };
  const handleReset = async (e) => {
    console.log("Handle click Reset called : ", e);
  };

  const toSqlDatetime = (inputDate) => {
    const date = new Date(inputDate);
    const dateWithOffest = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return dateWithOffest.toISOString().slice(0, 19).replace("T", " ");
  };

  const onChangeDatePicker = (e) => {
    console.log("Date onChange called ...", e);
    var formattedDt = toSqlDatetime(e);

    setFormValues({ ...formValues, ["studyDate"]: formattedDt });

    console.log("Formatted str DC date : ", formattedDt);
  };

  return (
    <>
      <div className="container">
        <Sidebar2 />
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={2} md={2} sm={2} lg={2}>
              <h1>Study</h1>
            </Col>
            <Col
              xs={10}
              md={10}
              sm={10}
              lg={10}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md">
                  <BsArrowLeftCircle
                    key={`back-btn-doctor-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleBack(e)}
                    name={`back-icon-doctor-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
                &nbsp;&nbsp;
                <Button variant="success" className="btn btn-success btn-md">
                  <BsFillPenFill
                    key={`edit-btn-doctor-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleEdit(e)}
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
                &nbsp;&nbsp;
                <Button variant="danger" className="btn btn-danger btn-md">
                  <BsFillTrashFill
                    key={`delete-btn-doctor-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => handleDelete(e)}
                    name={`delete-icon-doctor-name`}
                    id={`delete-icon-doctor-id`}
                  />
                </Button>
              </div>
            </Col>
          </Row>
          <Row style={{ height: props.height }}>
            <hr />
          </Row>

          <br></br>
          <div className="ui-divider"></div>
          <div className="ui-form">
            <Row>
              <Col xs={2} md={2} sm={2} lg={2}>
                <div className="field">
                  <label>Study Date:</label>
                </div>
              </Col>

              <Col xs={4} md={4} sm={4} lg={4}>
                <div className="field">
                  <DateTimePicker
                    onChange={onChangeDatePicker}
                    calendarIcon={null}
                    format="dd-MM-y HH:mm:ss"
                    value={formValues.studyDate}
                  />
                </div>
                <p className="error-msg">{formErrors.name}</p>
              </Col>

              <Col xs={2} md={2} sm={2} lg={2}>
                <div className="field">
                  <label>Ref. Doctor:</label>
                </div>
              </Col>

              <Col xs={4} md={4} sm={4} lg={4}>
                <div className="field col-6">
                  <Select
                    id="ref_doctor"
                    name="ref_doctor"
                    value={{
                      value: doctorIdSelect.value,
                      label: doctorIdSelect.label,
                    }}
                    options={optionsDoctor}
                    defaultValue={{
                      value: doctorIdSelect.value,
                      label: doctorIdSelect.label,
                    }}
                    onChange={handleDoctorSelect}
                    isDisabled={isReadOnly}
                  />
                </div>
                <p className="error-msg">{formErrors.ref_doctor}</p>
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={2} sm={2} lg={2}>
                <div className="field">
                  <label>Patient: </label>
                </div>
              </Col>

              <Col xs={4} md={4} sm={4} lg={4}>
                <div className="field  col-6">
                  <Select
                    id="patient"
                    name="patient"
                    value={{
                      value: patientIdSelect.value,
                      label: patientIdSelect.label,
                    }}
                    options={optionsPatient}
                    defaultValue={{
                      value: patientIdSelect.value,
                      label: patientIdSelect.label,
                    }}
                    onChange={handlePatientSelect}
                    isDisabled={isReadOnly}
                  />
                </div>
                <p className="error-msg">{formErrors.patient}</p>
              </Col>

              <Col xs={2} md={2} sm={1} lg={2}>
                <div className="field">
                  <label>Payment Type: </label>
                </div>
              </Col>

              <Col xs={4} md={4} sm={4} lg={4}>
                <div className="field">
                  <div key={`inline-radio`} className="mb-3">
                    <Form.Check
                      inline
                      label="PAID"
                      name="payment_mode"
                      type="radio"
                      id={`inline-radio-paid`}
                      checked="true"
                    />
                    <Form.Check
                      inline
                      label="FREE"
                      name="payment_mode"
                      type="radio"
                      id={`inline-radio-free`}
                    />
                  </div>
                </div>
                <p className="error-msg">{formErrors.payment}</p>
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={2} sm={2} lg={2}>
                <div className="field">
                  <label>Study: </label>
                </div>
              </Col>

              <Col xs={4} md={4} sm={4} lg={4}>
                <div className="field  col-6">
                  <Select
                    id="study"
                    name="study"
                    value={{
                      value: studyIdSelect.value,
                      label: studyIdSelect.label,
                    }}
                    options={optionsStudy}
                    defaultValue={{
                      value: studyIdSelect.value,
                      label: studyIdSelect.label,
                    }}
                    onChange={handleStudySelect}
                    isDisabled={isReadOnly}
                  />
                </div>
                <p className="error-msg">{formErrors.study}</p>
              </Col>

              <Col xs={2} md={2} sm={2}>
                <div className="field">
                  <label>Charges :</label>
                </div>
              </Col>
              <Col xs={4} md={4} sm={4}>
                <div className="field">
                  <input
                    type="text"
                    name="charges"
                    placeholder="Amount"
                    value={formValues.charges}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                  />
                </div>
                <p className="error-msg">{formErrors.charges}</p>
              </Col>
            </Row>

            <Row>
              <Col xs={2} md={2} sm={2}>
                <div className="field">
                  <label>Study Report :</label>
                </div>
              </Col>
              <Col xs={4} md={4} sm={4}>
                <div className="field">
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Control type="file" />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            {!isReadOnly && (
              <Row className="flex float-end">
                <Col xs={5} md={5} sm={5} lg={5}>
                  <Button
                    type="submit"
                    id="save"
                    onClick={(e) => handleSave(e)}
                  >
                    Save
                  </Button>
                </Col>

                <Col xs={7} md={7} sm={7} lg={7}>
                  <Button
                    variant="secondary"
                    id="Reset"
                    onClick={(e) => handleReset(e)}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddUpdateServiceTrans;
