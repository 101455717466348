import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View,Modal,Spinner } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { BsFillPenFill, BsFillTrashFill,BsPencilSquare,BsArrowLeftCircle } from "react-icons/bs";

import DateTimePicker from "react-datetime-picker";


const AddUpdateResetPassword = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);



  let initialItems = [];
  
  const intialValues = {
    user_name: "",
    first_name: "",
    last_name: "",
   
    user_role:""
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [toRender, setToRender] = useState(false);
  const [validateError, setValidateError] = useState(false);
  
  var validateErr = false;

 

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const [submitAction, setSubmitAction] = useState(false);

  const handleOnChange = (e) => {
    //e.preventDefault();
    console.log("handleOnChange : ", e);

    console.log("Button clicked : ", e);

    console.log("Clicked value : ", e.currentTarget);
    const { name, value } = e.currentTarget;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
  
    setFormValues({ ...formValues, [name]: value });
  }
  
 
  const handleValueChange = async (e) => {
    console.log("On change clicked : ", e);

    var tagId = e.target.id;
    var currentRowName = tagId.substring(tagId.lastIndexOf("-") + 1);
    var nameOfElement = tagId.substring(0, tagId.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);

   
  };
  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateProfile called ##########################"
      );
      //console.log("PASSED STATE VALUE IN Service Add/Update page : ", state);
     // getCompany();
     if ( state !== null ) {
      if (state.action === 'view' || state.action === 'edit') {
        console.log("PASSED STATE VALUE in AddUpdateResetPassword : ", state);
        setFormValues(state.passed_element);
      }
      
     }
      initialized.current = true;
      
    }
  }, []);
 /*
  const resetPassword = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}

      var apiToCall = "/core/resetPassword";
     
      var reqParam = {
        user_id:state.passed_element.user_id,
        user_password: formValues["new_password"]
      };

      var headers = client.headers;
      var res = await axios.post( client.domain + apiToCall, reqParam, { headers },{ withCredentials: client.withCredentialState });
      
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false); 
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          
        }
      }
      setSubmitAction(false);
    }
  };
  */

 
  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;
    if (!values.new_password) {
      errors.new_password = " New Password is required";
      validateErr = true;
      setValidateError(true);
    }

    if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password should not be empty";
      validateErr = true;
    }

    if ( values.new_password !== values.confirm_password ) {
      errors.new_password = " New password and confirm password should be same";
      errors.confirm_password = "New password and confirm password should be same";
      validateErr = true;
    }


    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  
  const handleEdit = async (e) => {
    console.log("handle edit clicked at AddUpdateCompany : ", e);
    setIsReadOnly(false);
  };
  
  const handleClear = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate(0);
  };
  
  const handleReset = async (e) => {
    e.preventDefault();
    console.log("Handle Click Reset called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    if (validateErr === false) {
      setSubmitAction(true);
      //resetPassword();
    }
  

  };
  
  const handleCloseDialog = () => {
    setShowDialog(false);
    //navigate("/ListCustomers");
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };
  const handleResetAction = () => {
    console.log("user confirmed to reset or not ...");
    setShowDialog(false);
    
  };

  return (
    <>
      <div className="container">
        <Sidebar2 />
        <Form>
          <Row>
          <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Reset Password</span>
            </Col>
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
            >
             
              <div className="d-flex justify-content-end">
              <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
               {/*  
              <Button variant="primary" className="btn btn-primary btn-md"
              key={`edit-btn-company-key`}
              style={{ cursor: "pointer" }}
              onClick={handleEdit}
              name={`edit-icon-password-name`}
              id={`edit-icon-password-id`}>
                  <BsPencilSquare
                    size={24}
                  />
                </Button>
               
               */}
                 </div>
                </Col> 
          </Row>
          <div className="ui-divider"></div>
          <div className="ui-form">
          <hr align="center" />
           
              <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required"> User Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="username"
                    name="username"
                    placeholder="User Name"
                    
                   // onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["username"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.username}</p>
              </Col>
            </Row>
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required"> New Password</Form.Label>
                  <Form.Control
                    type="text"
                    id="new_password"
                    name="new_password"
                    placeholder="New Password"
                    onChange={(e) => handleOnChange(e)}
                    //readOnly={isReadOnly}
                    value={formValues["new_password"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.new_password}</p>
              </Col> 
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required"> Confirm Password</Form.Label>
                  <Form.Control
                    type="text"
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    onChange={(e) => handleOnChange(e)}
                    //readOnly={isReadOnly}
                    value={formValues["confirm_password"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.confirm_password}</p>
              </Col> 
            </Row>
           
           
           
            <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleClear(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      //type="submit"
                      id="save"
                      onClick={(e) => handleReset(e)}
                      disabled={submitAction ? true: false}
                    >
                      {submitAction ? 
                      <>
                      <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
        </> : 
        'Reset'}
                      
                    </Button>
                  </div>
                </Col>  
              </Row>
              <Modal
                show={showDialog}
                onHide={handleCloseDialog}
                backdrop="static"
                keyboard={false}
               >
        <Modal.Header closeButton>
          <Modal.Title>{showMsgTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{showMsg}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleResetAction}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
           
            
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddUpdateResetPassword;
