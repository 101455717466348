import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View,Badge } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import {
  BsFillPenFill,
  BsFillTrashFill,
  BsFillArrowLeftCircleFill,
  BsArrowLeftCircle,
} from "react-icons/bs";

const AddUpdateReceipt = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);
  var receiptArr = [
    {
      SNo: 1,
      patientId: "BS0001",
      patientName: "Raj",
      studyId: "ST001",
      studyName: "Abdomemn Scan",
      charges: "Rs 3000",
      checkedState: false,
    },
    {
      SNo: 2,
      patientId: "BS0002",
      patientName: "Arul",
      studyId: "ST002",
      studyName: "Neck scan",
      charges: "Rs 1000",
      checkedState: false,
    },
    {
      SNo: 3,
      patientId: "BS0003",
      patientName: "Joel",
      studyId: "ST003",
      studyName: "Ultra Scan",
      charges: "Rs 2000",
      checkedState: false,
    },
  ];

  let initialItems = [];

  const intialValues = {
    id: "",
    name: "",
    mobileNumber: "",
    receivedAmount: 0.0,
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);
  var validateErr = false;

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);

  const [receiptList, setReceiptList] = useState(receiptArr);

  const [selectedIdx, setSelectedIdx] = useState();
  const [searchSuccess, setSearchSuccess] = useState(false);
  const [patientSelected, setPatientSelected] = useState(false);

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdatereceipt called ##########################"
      );
      /*
      if (state === null) {
        navigate("/listReceipts");
      } else {
        console.log("PASSED STATE VALUE IN Doctor Add/Update page : ", state);
      }
      */
      initialized.current = true;
    }
  }, []);

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.patientName) {
      errors.patientName = "Patient name is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.patientId) {
      errors.patientId = "Patient Id  is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.studyName) {
      errors.studyName = "Study name is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.studyId) {
      errors.studyId = "Study Id  is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.mobileNumber) {
      errors.mobileNumber = "Mobile number is required";
      validateErr = true;
      setValidateError(true);
    }

    if (values.mobileNumber.length != 10) {
      errors.mobileNumber = "Mobile number should be 10 digits";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.receivedAmount) {
      errors.receivedAmount = "Received Amount is required";
      validateErr = true;
      setValidateError(true);
    }
    if (values.studyId.length != 10) {
      errors.studyId = "Study Id should be 3 characters";
      validateErr = true;
      setValidateError(true);
    }
    if (values.patientId.length != 10) {
      errors.patientId = "Patient Id should be 3 characters";
      validateErr = true;
      setValidateError(true);
    }

    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ",
      validateError
    );
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      console.log("LINE ITEMS : ", items);
      console.log("Form submit called ...");

      console.log("patient Name : ", formValues["name"]);
    }
  };
  const handleSearch = async (e) => {
    console.log("Handle Click search called : ", e);

    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErrSearch = false;
    setFormErrorsSearch(validateSearch(formValuesSearch));
    setIsSubmitSearch(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ",
      validateErrorSearch
    );

    console.log("^^^^^^^^^^^^ IS submit search : ", isSubmitSearch);

    setSearchSuccess(true);
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit  called : ", e);
    setIsReadOnly(false);
  };

  const handleDelete = async (e) => {
    console.log("Handle delete  called : ", e);
  };

  const handleSave = async (e) => {
    console.log("Handle Click save called : ", e);
  };
  const handleReset = async (e) => {
    console.log("Handle click Reset called : ", e);
    setSearchSuccess(false);
    setPatientSelected(false);
  };

  const handleClearSearch = async (e) => {
    e.preventDefault();
    console.log("Handle click  Clear Search called : ", e);
    setSearchSuccess(false);
    setPatientSelected(false);
  };
  const receiptRowClick = async (e, id) => {
    console.log("Receipt row clicked : ", e);
    console.log("Clicked at : ", id.idx);
  };
  const handleNewStudy = async (e) => {
    console.log("handle new study clicked : ", e);
    setPatientSelected(true);
    setSearchSuccess(false);
  };

  var today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  var studyDateStr = `${date}-${month}-${year}`;

  const intialValuesSearch = {
    searchMobile: "",
  };

  const [formValuesSearch, setFormValuesSearch] = useState(intialValuesSearch);
  const [formErrorsSearch, setFormErrorsSearch] = useState({});
  const [validateErrorSearch, setValidateErrorSearch] = useState(false);
  var validateErrSearch = false;
  const [isSubmitSearch, setIsSubmitSearch] = useState(false);

  const validateSearch = (values) => {
    console.log("validateErr before start processing SEARCH : ", validateErr);
    console.log("Validate called SEARCH ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    /*
    if (!values.searchMobile) {
      errors.searchMobile = "Search Mobile number is required";
      validateErrSearch = true;
      setValidateErrorSearch(true);
    }

    if (values.searchMobile.length != 10) {
      errors.searchMobile = "Search Mobile number should be 10 digits";
      validateErrSearch = true;
      setValidateErrorSearch(true);
    }
    */

    console.log("BEFORE RETURN validate errors ...", validateErrorSearch);
    console.log("VALIDATE ERROR inside validate func : ", validateErrSearch);
    return errors;
  };

  return (
    <>
      <Container>
        <Sidebar2 />
        <Form onSubmit={handleSearch}>
        <Row>
          <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Receipt</span>
            </Col>  
          </Row>
        

          <Row>
            <Col xs={2} md={2} sm={2} lg={2}>
            <Form.Group className="mb-3"> 
               <Form.Control
              
                type="number"
                name="mobile_number"
                className="mobNum"
                id="search-mobile-id"
                placeholder="Patient Mobile"
                value={formValues.mobile_number}
                onChange={(e) => handleValueChange(e)}
              />
              </Form.Group>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <p className="error-msg">{formErrors.mobile_number}</p>
              &nbsp;&nbsp;
            </Col>

            <Col xs={2} md={2} sm={2} lg={2}>
            <Form.Group className="mb-3"> 
               <Form.Control
              
                type="text"
                name="first_name"
                id="search-name-id"
                placeholder="Patient Name"
                value={formValues.first_name}
                onChange={(e) => handleValueChange(e)}
              />
              </Form.Group>
              <p className="error-msg">{formErrors.first_name}</p>
              &nbsp;&nbsp;
            </Col>
            <Col xs={4} md={4} sm={4} lg={4}>
              &nbsp;&nbsp;
              <Button id="btn-search-id" name="btn-search-name" type="submit">
                Search
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="secondary"
                id="btn-search-id"
                name="btn-search-name"
                onClick={handleClearSearch}
              >
                Clear Search
              </Button>
            </Col>
          </Row>
        </Form>
        {/*
        <Row style={{ height: props.height }}>
          <hr />
        </Row>
  */}
        <br></br>
        {searchSuccess && (
          <div className="ui-form">
            <Row>
              <Col xs={5} md={5} sm={5} lg={5}>
                <h3> Studies </h3>
              </Col>
            </Row>
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading">...</th>
                  <th className="table-row-heading"key={0}>S.No</th>
                  <th className="table-row-heading">Patient Id</th>
                  <th className="table-row-heading">Patient Name</th>
                  <th className="table-row-heading">Study Id</th>
                  <th className="table-row-heading">Study Name</th>
                  <th className="table-row-heading">Charges</th>
                </tr>
              </thead>
              <tbody>
                {receiptList.map((receipt, idx) => (
                  <tr
                    key={`receipt-tr-key-${idx}`}
                    onClick={(e, id = { idx }) => receiptRowClick(e, id)}
                    id={`receipt-tr-id-${idx}`}
                    name={`receipt-tr-name-${idx}`}
                    charges={`receipt-tr-charges-${idx}`}
                  >
                    <Form.Check
                      name="receipt"
                      type="radio"
                      checked={receipt.checkedState}
                    ></Form.Check>
                    <td key={idx}>{idx + 1}</td>
                    <td>{receipt.patientId}</td>
                    <td>{receipt.patientName}</td>
                    <td>{receipt.studyId}</td>
                    <td>{receipt.studyName}</td>
                    <td>{receipt.charges}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Row className="justify-content-center">
              <Col xs={4} sm={4} md={4} lg={4}>
                <Button variant="primary" onClick={handleNewStudy}>
                  Enter Receipt details
                </Button>
              </Col>
            </Row>
          </div>
        )}
        {patientSelected && (
          <div className="Container">
           
            <Row>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h4 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Bill Details
                    </Badge>
                  </h4>
                </Col>
              </Row>
              
            <div className="ui-form">
              <Row>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <div className="field">
                    <Form.Label>Patient Id:</Form.Label>
                  </div>
                </Col>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <div className="field">
                    <Form.Label>BS0002</Form.Label>
                  </div>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <div className="field">
                    <Form.Label>Patient Name:</Form.Label>
                  </div>
                </Col>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <div className="field">
                    <Form.Label>Arul</Form.Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <div className="field">
                    <Form.Label>Study Id:</Form.Label>
                  </div>
                </Col>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <div className="field">
                    <Form.Label>ST002</Form.Label>
                  </div>
                </Col>

                <Col xs={2} md={2} sm={2} lg={2}>
                  <div className="field">
                    <Form.Label>Study Name:</Form.Label>
                  </div>
                </Col>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <div className="field">
                    <Form.Label>Neck scan</Form.Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <div className="field">
                    <Form.Label>Study Date:</Form.Label>
                  </div>
                </Col>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <div className="field">
                    <Form.Label>{studyDateStr}</Form.Label>
                  </div>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <div className="field">
                    <Form.Label>Charges:</Form.Label>
                  </div>
                </Col>
                <Col xs={4} md={4} sm={4} lg={4}>
                  <div className="field">
                    <Form.Label>Rs 1000</Form.Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h4 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Receipt Details
                    </Badge>
                  </h4>
                </Col>
              </Row>

              <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Received Amount</Form.Label>
                  <Form.Control
              
                    type="text"
                    id="received-amount-id"
                    name="receivedAmount"
                    placeholder="Received amount"
                    value={formValues.receivedAmount}
                    onChange={(e) => handleValueChange(e)}
                  />
                  </Form.Group>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Received Mode</Form.Label>
                  <Form.Control
              
                    type="text"
                    name="received-mode"
                    placeholder="Received mode"
                    value={formValues.receivedMode}
                    readOnly={isReadOnly}
                  />
                  </Form.Group>
                </Col>
              </Row>
             
              <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Note</Form.Label>
                  <Form.Control
               
                    type="text"
                    name="note"
                    placeholder="Note"
                    value={formValues.note}
                    width="25"
                    readOnly={isReadOnly}
                  /></Form.Group>
                </Col>
              </Row>
            
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                     
                    >
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
          
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default AddUpdateReceipt;
