import React, {useState, useEffect, useRef} from "react";
import {Container, Row, Col, Card, Form, Button, View } from "react-bootstrap";

import '../Dashboard.css'

const Footer = props => {
   
    //const handleSelect = (eventKey) => {
    //    alert(`selected ${eventKey}`);
    //}
    return (      
      <>        
             
          <Container fluid >
            <p>Powered by <a href="https://avinasoft.com/"> Avinasoft </a> 
            <span>
            Copyright © 2023 <a href="https://kmcreadymix.avinasoft.in/">KMC ReadyMix</a>. All rights reserved.
            </span></p>
          </Container>
    </>
        );
  };
  //const Sidebar = withRouter(Side);
  export default Footer