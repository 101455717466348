import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Spinner,
  Modal
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, regexAlphabets, regexAlphaNumeric } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import {
  BsFillPenFill,
  BsFillTrashFill,
  BsFillArrowLeftCircleFill,
  BsArrowLeftCircle,
  BsPencilSquare
} from "react-icons/bs";

const AddUpdateDoctor = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);

  let initialItems = [];
  const optionsDistrict = [
    { value: 1, label: "Coimbatore" },
    { value: 2, label: "Tiruppur" },
    { value: 3, label: "Erode" },
    { value: 4, label: "Karur" },
    { value: 5, label: "Namakkal" },
    { value: 6, label: "Ooty" },
    { value: 7, label: "Salem" },
  ];

  const intialValues = {
    first_name: "",
    last_name: "",
    mobile_primary: "",
    mobile_secondary: "",
    hospital: "",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  //const [validateError, setValidateError] = useState(false);
  const [districtIdSelect, setDistrictIdSelect] = useState({});
  var validateErr = false;

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [optDist, setOptDist] = useState(optionsDistrict);

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [submitAction, setSubmitAction] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateDoctor called ##########################"
      );

      if (state === null) {
        navigate("/listDoctors");
      } else {
        console.log("PASSED STATE VALUE IN Doctor Add/Update page : ", state);

        if (state.action === "add") {
          setIsReadOnly(false);
        } else if (state.action === "view") {
          setFormValues(state.passed_element);
        } else {
          setIsReadOnly(false);
        }
      }
      initialized.current = true;
    }
  }, []);

  const handleOnChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    //setFormValues({ ...formValues, [name]: value });
    if (name === "mobile_primary" || name === "mobile_secondary" || name === "pincode") {
      console.log("Inside check ******************");
      
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
     
      setFormValues({ ...formValues, [name]: valueTmp });
      //}
    } else {
      setFormValues({ ...formValues, [name]: value });
    }

  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.first_name) {
      errors.first_name = "First name is required";
      validateErr = true;
      //setValidateError(true);
    }
    if (!values.last_name) {
      errors.last_name = "Last name is required";
      validateErr = true;
      //setValidateError(true);
    }
    if (!values.hospital) {
      errors.hospital = "Hospital is required";
      validateErr = true;
      //setValidateError(true);
    }
    /*
    if (!values.city) {
      errors.city = "City is required";
      validateErr = true;
      //setValidateError(true);
    }
    */

    if (!values.mobile_primary) {
      errors.mobile_primary = "Mobile number is required";
      validateErr = true;
      //setValidateError(true);
    }

    if (values.mobile_primary && values.mobile_primary.length != 10) {
      errors.mobile_primary = "Mobile number should be 10 digits";
      validateErr = true;
      //setValidateError(true);
    }
    /*
    if (!values.secondaryNumber) {
      errors.secondaryNumber = "Mobile number is required";
      validateErr = true;
      setValidateError(true);
    }
    */

    if (values.mobile_secondary && values.mobile_secondary.length != 10) {
      errors.mobile_secondary = "Mobile number should be 10 digits";
      validateErr = true;
      //setValidateError(true);
    }

    //console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  const handleDistrictSelect = (e, data) => {
    console.log("handleDistrictSelect called ...", e);
    setDistrictIdSelect(e);

    var DistrictIdInput = e.value;
    var DistrictNameInput = e.label;
    //getDistrictList(stateIdInput, stateNameInput);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    /*
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ",
      validateError
    );
    */
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      //console.log("LINE ITEMS : ", items);
      callUpdateApi();
      //console.log("Form submit called ...");

      //console.log("Doctor Name : ", formValues["first_name"]);
    }
  };

  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };
  const handlePostSaveOk = () => {
    console.log("user confirmed to patient added ...");
    
    setShowDialog(false);
    navigate("/listDoctors");
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate("/ListDoctors");
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit doctor called : ", e);
    setIsReadOnly(false);
  };

  const handleDelete = async (e) => {
    console.log("Handle delete doctor called : ", e);
  };

  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {
        ...client.headers,
        Authorization: `Bearer ${aIVal}`
      }
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/dcl/addPhysician";
      } else if (state.action === "view") {
        apiToCall = "/dcl/updatePhysician";
      } else {
        apiToCall = "/dcl/addPhysician";
      }
      var headers = client.headers;
      var res = await axios.post(
        client.domain + apiToCall,
        formValues,
        { headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        //console.log("Response message from server : ", res.data.detail.res_data);
        //console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        //console.log("Response message from server aI : ", res.data.detail.res_data.aI);
        // Remove login session from localstorage
        //localStorage.removeItem("username");
        //localStorage.removeItem("_aI")
        //navigate("/auth");
        setShowMsg(res.data.detail.data.message);
       // setShowMsgTitle("Doctor Info updated successfully");
        setShow(true);
        setShowDialog(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle("LOGIN FAILED!");
          //setShow(true);
        }
      }
      setSubmitAction(false);
    }
  };
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      callUpdateApi();
     
    }
  };

 
  const handleReset = async (e) => {
    console.log("Handle click Reset called : ", e);
    console.log("state.passed_element : ", e);
    if (state.action === "add") {
      setFormValues(intialValues);
    } else {
      setFormValues(state.passed_element);
    }
    //setFormValues({ ...formValues, ["first_name"]: "" });
    console.log("Formvalues :", e);
  };

  const handleAlertClose = async (e) => {
    console.log("handle alert close called : ", e);
    setShow(false);
    navigate(-1);
  };

  return (
    <>
      <Container>
        <Sidebar2 />
        <Form >
        <Row>
            <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Doctor</span>
            { (state.action === 'view' || state.action === 'edit') &&
              <>
              &nbsp;&nbsp;
             
            <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
            </>
            }
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}>
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
                </>
                )}
              </div>
            </Col>
          </Row>
          
          <div className="ui-divider"></div>
          <div className="ui-form">
          <hr align="center" />
            
            <Row>
            <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="first_name"
                    name="first_name"
                    placeholder=" First Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["first_name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.first_name}</p>
              </Col>
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="last_name"
                    name="last_name"
                    placeholder=" Last Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["last_name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.last_name}</p>
              </Col>
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Hospital</Form.Label>
                  <Form.Control
                    type="text"
                    id="hospital"
                    name="hospital"
                    placeholder=" Hospital"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["hospital"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.hospital}</p>
              </Col>
            </Row>
            <Row>
            <Col xs={4} md={4} sm={4} lg={4}>
              <Form.Group className="mb-3">
                  <Form.Label className="field required">Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile_primary"
                   
                    placeholder="Mobile"
                    value={formValues["mobile_primary"]}
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                    maxLength="10"
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
               </Form.Group>
                <p className="error-msg">{formErrors.mobile_primary}</p>
              </Col>
             
              <Col xs={4} md={4} sm={4} lg={4}>
              <Form.Group className="mb-3">
                  <Form.Label>Alternative Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile_secondary"
                    
                    placeholder="Alternative Mobile"
                    value={formValues["mobile_secondary"]}
                    onChange={handleOnChange}
                    readOnly={isReadOnly}
                    maxLength="10"
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                  </Form.Group>
                <p className="error-msg">{formErrors.mobile_secondary}</p>
              </Col>
              
            </Row>
            {!isReadOnly && (
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction ? 
                      <>
                      <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
        </> : 
        'Save'}
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}
            <Modal
              show={showDialog}
              onHide={handleCloseDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>Success</Modal.Title>
              </Modal.Header>
              <Modal.Body>Doctor Created Successfully...!</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
         
        </Form>
      </Container>
    </>
  );
};

export default AddUpdateDoctor;
