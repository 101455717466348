import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
  Spinner,
  Modal
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";

import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client, regexAlphabets, regexAlphaNumeric } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import {
  BsFillPenFill,
  BsFillTrashFill,
  BsFillArrowLeftCircleFill,
  BsArrowLeftCircle,
  BsPencilSquare,
} from "react-icons/bs";

const AddUpdatePatient = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);

  let initialItems = [];

  let intialValues = {
    first_name: "",
    last_name: "",
    mobile_primary: "",
    mobile_secondary: "",
    old_patient_id: "",
    age: "",
    gender: "",
    address_line1: "",
    address_line2: "",
    district_id: 0,
    pincode: "",
  };

  const optionsDistrict = [
    { value: 1, label: "Coimbatore" },
    { value: 2, label: "Tiruppur" },
    { value: 3, label: "Erode" },
    { value: 4, label: "Karur" },
    { value: 5, label: "Namakkal" },
    { value: 6, label: "Ooty" },
    { value: 7, label: "Salem" },
  ];

  const [districtIdSelect, setDistrictIdSelect] = useState({});

  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  //const [validateError, setValidateError] = useState(false);
  var validateErr = false;

  const [isSubmit, setIsSubmit] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [items, setItems] = useState(initialItems);
  // const [mobile, setMobile] = useState({});
  //const [iserror, setIsError] = useState({});

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [submitAction, setSubmitAction] = useState(false);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  const [modalTitleText, setModalTitleText] = useState("Success");
  const [modalBodyText, setModalBodyText] = useState("Patient created successfully!")

  useEffect(() => {
    console.log("-----------useEffect called ************");
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdatePatient called ##########################"
      );
      console.log("PASSED VALUE LENGTH : ", state);
      if (state === null) {
        //console.log("############## state called : ", state);
        navigate("/listPatients");
      } else {
        console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);

        if (state.action === "view" || state.action === 'edit') {
          setFormValues(state.passed_element);

          const searchedElement = optionsDistrict.find(
            (ele) => ele.value === state.passed_element.district_id
          );
          console.log("searched element : ", searchedElement);
          setDistrictIdSelect(searchedElement);
        }
        else {
          console.log("optionsDistrict :" , optionsDistrict[0]);
          setDistrictIdSelect(optionsDistrict[0]);
          setFormValues({ ...formValues, ["gender"]: "MALE"});
        }
      }
      if (state === null) {
        navigate("/listPatients");
      } else {
        console.log("PASSED STATE VALUE IN Doctor Add/Update page : ", state);
        //setFormValues(state.passed_element);

        if (state.action === "add") {
          setIsReadOnly(false);
        } else if (state.action === "view") {
          setFormValues(state.passed_element);
        } else {
          setIsReadOnly(false);
        }
      }
      initialized.current = true;
    }
  }, []);

  const handleValueChange = (e) => {
    //e.preventDefault();

    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    //setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
    //const regex = /^[A-Za-z]+$/; // Regular expression to allow only alphabets
    //const { value } = e.target;
    
    if (name === "mobile_primary" || name === "mobile_secondary" || name === "pincode" ||  name === "age") {
      console.log("Inside check ******************");
      
      var valueTmp = e.currentTarget.value.replace(/[^\d]/g, '');
     
      setFormValues({ ...formValues, [name]: valueTmp });
      //}
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.first_name) {
      errors.first_name = "Patient name is required";
      validateErr = true;
      //setValidateError(true);
    }
    if (!values.age) {
      errors.age = "Age is required";
      validateErr = true;
      //setValidateError(true);
    }
    if (!values.address_line2) {
      errors.address_line2 = "City / Town is required!";
      validateErr = true;
      //setValidateError(true);
    }

    if (!values.mobile_primary) {
      errors.mobile_primary = "Mobile number is required";
      validateErr = true;
      //setValidateError(true);
    }

    if (values.mobile_primary && values.mobile_primary.length != 10) {
      errors.mobile_primary = "Mobile number should be 10 digits";
      validateErr = true;
      //setValidateError(true);
    }
    {
      /* if (!values.mobile_primary.match("[0-9]{10}")) {
      alert("Please provide valid phone number");
    }
  */
    }
   

    if (!values.gender) {
      errors.gender = "Gender should be selected";
      validateErr = true;
    }

    //console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    /*
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ",
      validateError
    );
    */
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      //console.log("LINE ITEMS : ", items);
      callUpdateApi();
      //console.log("Form submit called ...");

      //console.log("Patient Name : ", formValues["patient_name"]);
    }
  };
  const handlePostSaveOk = () => {
    console.log("user confirmed to patient added ...");
    
    setShowDialog(false);
    navigate("/listPatients");
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate("/ListPatients");
  };

  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit doctor called : ", e);
    setIsReadOnly(false);
  };

  const handleDelete = async (e) => {
    console.log("Handle delete doctor called : ", e);
  };

  const handleDistrictSelect = async (e) => {
    console.log("handle district id select called : ", e);

    setDistrictIdSelect(e);

    setFormValues({ ...formValues, ["district_id"]: e.value });
  };
  const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {
        ...client.headers,
        Authorization: `Bearer ${aIVal}`
      }
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/dcl/addPatient";
      } else if (state.action === "view") {
        apiToCall = "/dcl/updatePatient";
      } else {
        apiToCall = "/dcl/addPatient";
      }
      var headers = client.headers;

      var patientIdTmp = 0;
      if ( state.action === 'edit' || state.action === 'view' ) {
        patientIdTmp = state.passed_element.patient_id;
      }

      var reqParam = {
        patient_id: patientIdTmp,
        gender: formValues["gender"],
        district_id: districtIdSelect.value,
        first_name: formValues["first_name"],
        last_name: formValues ["last_name"],
        mobile_primary: formValues["mobile_primary"],
        mobile_secondary: formValues["mobile_secondary"],
        age: formValues["age"],
        address_line1: formValues["address_line1"],
        address_line2: formValues["address_line2"],
        pincode: formValues ["pincode"]

      }

      //setFormValues({ ...formValues, ["last_name"]: "" });
      //setFormValues({ ...formValues, ["mobile_secondary"]: "" });
      var res = await axios.post(
        client.domain + apiToCall,
        reqParam,
        { headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        //console.log("Response message from server : ", res.data.detail.res_data);
        //console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        //console.log("Response message from server aI : ", res.data.detail.res_data.aI);
        // Remove login session from localstorage
        //localStorage.removeItem("username");
        //localStorage.removeItem("_aI")
        //navigate("/auth");
        if ( state.action === 'edit' || state.action === 'view' ) {
          setModalTitleText("Successs");
          setModalBodyText("Patient Updated successfully!");
        }
        setShowMsg(res.data.detail.data.message);
        //setShowMsgTitle("Patient Info updated successfully");
        //setShow(true);
        setShowDialog(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle("LOGIN FAILED!");
          //setShow(true);
        }
      }
      setSubmitAction(false);
    }
  };
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      callUpdateApi();
     
    }
  };

  
  const handleReset = async (e) => {
    console.log("Handle click Reset called : ", e);
    console.log("state.passed_element : ", e);
    if (state.action === "add") {
      setFormValues(intialValues);
    } else {
      setFormValues(state.passed_element);
    }
    //setFormValues({ ...formValues, ["first_name"]: "" });
    console.log("Formvalues :", e);
  };

  const handleOnChange = async (e) => {
    console.log("On change clicked : ", e);

    var tagId = e.target.id;
    var currentRowName = tagId.substring(tagId.lastIndexOf("-") + 1);
    var nameOfElement = tagId.substring(0, tagId.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);

    setFormValues({ ...formValues, ["gender"]: currentRowName.toUpperCase() });
  };

  const handleAlertClose = async (e) => {
    console.log("handle alert close called : ", e);
    setShow(false);
    navigate(-1);
  };

  const onkeyDownInText = async (e) => {
    console.log("onkeyDown fired : ", e);

    return /[a-zA-Z]/i.test(e.key);
  };

  return (
    <>
      <Container>
        <Sidebar2 />
        <Form >
          <Row>
          <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem", verticalAlign:"bottom" }}>Patient</span>
            </Col>

           
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    name={`back-icon-patient-name`}
                    id={`back-icon-patient-id`}
                  />
                </Button>
                &nbsp;
                {state.action === "view" && (
                  <>
                    &nbsp;
                    <Button
                      variant="primary"
                      className="btn btn-primary btn-md"
                      onClick={(e) => handleEdit(e)}
                    >
                      <BsPencilSquare
                        key={`edit-btn-patient-key`}
                        size={24}
                        style={{ cursor: "pointer" }}
                        name={`edit-icon-paBiSolidEditAlttient-name`}
                        id={`edit-icon-doctor-id`}
                      />
                    </Button>
                  </>
                )}
                &nbsp;&nbsp;
              </div>
            </Col>
          </Row>
          <hr align="center" />
          

          <div className="ui-divider"></div>
          <div className="ui-form">
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="first_name"
                    name="first_name"
                    placeholder="Patient First Name"
                    onChange={(e) => handleValueChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["first_name"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.first_name}</p>
              </Col>
              
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label className="field required">Age</Form.Label>
                  <Form.Control
                  type="text"
                    id="age"
                    name="age"
                    placeholder="Age"
                    onChange={(e) => handleValueChange(e)}
                    readOnly={isReadOnly}
                    maxLength={3}
                    value={formValues["age"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                  </Form.Group>
                <p className="error-msg">{formErrors.age}</p>
              </Col>
              
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label className="field required">Gender</Form.Label>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check
                    disabled={isReadOnly}
                    inline
                    label="Male"
                    name="gender"
                    type={"radio"}
                    id={`inline-radio-male`}
                    onChange={(e) => handleOnChange(e)}
                    checked={ formValues["gender"] === 'MALE' ? true : false}
                  />
                  <Form.Check
                    disabled={isReadOnly}
                    inline
                    label="Female"
                    name="gender"
                    type={"radio"}
                    id={`inline-radio-female`}
                    onChange={(e) => handleOnChange(e)}
                    checked={ formValues["gender"] === 'FEMALE' ? true : false}
                  />
                  
                </div>
                </Form.Group>
                <p className="error-msg">{formErrors.gender}</p>
              </Col>
           
            <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label className="field required">Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile_primary"
                   
                    placeholder="Mobile"
                    value={formValues.mobile_primary}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    maxLength="10"
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
               </Form.Group>
                <p className="error-msg">{formErrors.mobile_primary}</p>
              </Col>
              </Row>
              <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label>Alternative Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile_secondary"
                    
                    placeholder="Alternative Mobile"
                    value={formValues.mobile_secondary}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                  </Form.Group>
                <p className="error-msg">{formErrors.mobile_secondary}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label className="field required">Ref.Id</Form.Label>
                  <Form.Control
                    type="text"
                    name="old_patient_id"
                   
                    placeholder="Old Patient Id"
                    value={formValues.old_patient_id}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                  </Form.Group>
               
                <p className="error-msg">{formErrors.old_patient_id}</p>
              </Col>
            </Row>

            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label>Street / Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="address_line1"
                    placeholder="Street / Location"
                    value={formValues.address_line1}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                  </Form.Group>
               
                <p className="error-msg">{formErrors.address_line1}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label className="field required">City / Town</Form.Label>
                  <Form.Control
             
                    type="text"
                    name="address_line2"
                    placeholder="City/Town"
                    value={formValues.address_line2}
                    onChange={handleValueChange}
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                
                <p className="error-msg">{formErrors.address_line2}</p>
              </Col>
            
                <Col xs={3} md={3} sm={3} lg={3}>
                 <Form.Group className="mb-3">
                  <Form.Label>District</Form.Label>
                 
                  <Select
                    id="district"
                    name="district_id"
                    value={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    options={optionsDistrict}
                    defaultValue={{
                      value: districtIdSelect.value,
                      label: districtIdSelect.label,
                    }}
                    onChange={handleDistrictSelect}
                    isDisabled={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                  
                  </Form.Group>
              
                <p className="error-msg">{formErrors.district_id}</p>
              </Col>
              
              <Col xs={3} md={3} sm={3} lg={3}>
                 <Form.Group className="mb-3">
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control
                    type="text"
                    name="pincode"
                    
                    placeholder="Pincode"
                    value={formValues.pincode}
                    onChange={handleValueChange}
                    maxLength="6"
                    readOnly={isReadOnly}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                  </Form.Group>
              
                <p className="error-msg">{formErrors.pincode}</p>
              </Col>
            </Row>
            {!isReadOnly && (
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      disabled={submitAction ? true: false}
                    >
                     {submitAction ? 
                      <>
                      <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
        </> : 
        'Save'}
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}
            <Modal
              show={showDialog}
              onHide={handleCloseDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{modalTitleText}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{modalBodyText}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
           
          </div>
          
          
        </Form>
      </Container>
    </>
  );
};

export default AddUpdatePatient;
