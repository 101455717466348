import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  View,
  Alert,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import {
  BsFillPenFill,
  BsFillTrashFill,
  BsFillArrowLeftCircleFill,
  BsArrowLeftCircle,
  BsPencilSquare
} from "react-icons/bs";

const AddUpdateService = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Service Add/Update page : ", state);

  let initialItems = [];

  const intialValues = {
    id: 0,
    name: "",
    description: "",
    charge: 0.0,
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);
  var validateErr = false;

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateService called ##########################"
      );
      if (state === null) {
        navigate("/listServices");
      } else {
        console.log("PASSED STATE VALUE IN Service Add/Update page : ", state);
        setFormValues(state.passed_element);

        if (state.action === "add") {
          setIsReadOnly(false);
        }
      }
      initialized.current = true;
    }
  }, []);

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.service_name) {
      errors.service_name = " Name is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.description) {
      errors.description = " Description is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.charges) {
      errors.charges = " Charge is required";
      validateErr = true;
      setValidateError(true);
    }

    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ",
      validateError
    );
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      // console.log("LINE ITEMS : ", items);
      callUpdateApi();
      //console.log("Form submit called ...");

      // console.log("Service Name : ", formValues["name"]);
    }
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit service called : ", e);
    setIsReadOnly(false);
  };

  const handleDelete = async (e) => {
    console.log("Handle delete service called : ", e);
  };
  const callUpdateApi = async () => {
    try {
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/dcl/addService";
      } else if (state.action === "view") {
        apiToCall = "/dcl/updateService";
      } else {
        apiToCall = "/dcl/addService";
      }
      var headers = client.headers;
      var res = await axios.post(
        client.domain + apiToCall,
        formValues,
        { headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        //console.log("Response message from server : ", res.data.detail.res_data);
        //console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        //console.log("Response message from server aI : ", res.data.detail.res_data.aI);
        // Remove login session from localstorage
        //localStorage.removeItem("username");
        //localStorage.removeItem("_aI")
        //navigate("/auth");
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("Service Info updated successfully");
        setShow(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle("LOGIN FAILED!");
          //setShow(true);
        }
      }
    }
  };

  const handleSave = async (e) => {
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    callUpdateApi();
  };
  const handleReset = async (e) => {
    console.log("Handle click Reset called : ", e);
    console.log("state.passed_element : ", e);
    if (state.action === "add") {
      setFormValues(intialValues);
    } else {
      setFormValues(state.passed_element);
    }
    //setFormValues({ ...formValues, ["first_name"]: "" });
    console.log("Formvalues :", e);
  };
  const handleOnChange = async (e) => {
    console.log("On change clicked : ", e);

    var tagId = e.target.id;
    var currentRowName = tagId.substring(tagId.lastIndexOf("-") + 1);
    var nameOfElement = tagId.substring(0, tagId.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);

    //setFormValues({ ...formValues, ["gender"]: currentRowName.toUpperCase() });
  };

  const handleAlertClose = async (e) => {
    setShow(false);
    navigate(-1);
  };

  return (
    <>
      <Container>
        <Sidebar2 />
        <Form >
          <Row>
          <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Service</span>
            { (state.action === 'view' || state.action === 'edit') &&
              <>
              &nbsp;&nbsp;
             
            <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
            </>
            }
            </Col>
            
            <Col
              xs={8}
              md={8}
              sm={8}
              lg={8}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}>
                  <BsPencilSquare
                    key={`edit-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                  
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-doctor-id`}
                  />
                </Button>
                </>
                )}
              </div>
           </Col>
          </Row>
          <hr align="center" />
         
          <div className="ui-divider"></div>
          <div className="ui-form">
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Category</Form.Label>
              <div key={`inline-radio`} className="mb-3">
                <Form.Check
                  inline
                  label="SCAN"
                  name="category_mode"
                  type="radio"
                  id={`inline-radio-scan`}
                  checked="true"
                />
                <Form.Check
                  inline
                  label="LAB"
                  name="category_mode"
                  type="radio"
                  id={`inline-radio-lab`}
                />
              </div>
              </Form.Group>

              <p className="error-msg">{formErrors.category}</p>
            </Col>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="name-id"
                    name="name"
                    placeholder="Service Name"
                    onChange={(e) => handleValueChange(e)}
                    readOnly={isReadOnly}
                    value={formValues.service_name}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.service_name}</p>
              </Col>
           
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Description</Form.Label>
                  <Form.Control
                    type="text"
                    id="description"
                    name="description"
                    placeholder="Description"
                    onChange={(e) => handleValueChange(e)}
                    readOnly={isReadOnly}
                    value={formValues.description}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.description}</p>
              </Col>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Charges</Form.Label>
                  <Form.Control
                    type="text"
                    id="charges"
                    name="charges"
                    placeholder="Charges"
                    onChange={(e) => handleValueChange(e)}
                    readOnly={isReadOnly}
                    value={formValues.charges}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.charges}</p>
              </Col>
              
            </Row>

            
            {!isReadOnly && (
              <Row className="flex float-end">
                 <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      //disabled={submitAction ? true: false}
                    >
                     Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
            )}
          </div>
          {show === true && (
            <Alert
              style={{
                display: "inline-block",
                width: "40%",
                alignItems: "right",
              }}
              variant="success"
              onClose={() => handleAlertClose()}
              dismissible
            >
              <Alert.Heading>{showMsgTitle}</Alert.Heading>
              <p>{showMsg}</p>
            </Alert>
          )}
        </Form>
      </Container>
    </>
  );
};

export default AddUpdateService;
