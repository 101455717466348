import React, { useState, useEffect, useRef } from "react";

import Spinner from "react-bootstrap/Spinner";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { useNavigate } from "react-router-dom";

import { client } from "../config/Config";
import axios from "axios";

function SplashScreen() {
  const [justifyActive, setJustifyActive] = useState("tab1");

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  const navigate = useNavigate();

  const validateSession = async (usernameVal, aIVal) => {
    let reqValidateJwt = {
      username: usernameVal,
      aI: aIVal,
    };

    var headers = client.headers;
    console.log("payload:", reqValidateJwt);

    try {
      var res = await axios.post(
        client.domain + "/validateJwt",
        reqValidateJwt,
        { headers },
        { withCredentials: true }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server : ",
          res.data.detail.res_data
        );
        navigate("/addUpdateServiceTransV3");
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg('Please Re-Login');
          //setShowMsgTitle('Invalid Session!');
          //setShow(true);
          navigate("/auth");
        }
      }
    }
  };

  useEffect(() => {
    var aIVal = localStorage.getItem("_aI");
    var usernameVal = localStorage.getItem("username");
    console.log("aI Session : ", aIVal);
    console.log("Username : ", usernameVal);
    if (
      usernameVal !== null &&
      usernameVal !== "" &&
      aIVal !== null &&
      aIVal !== ""
    ) {
      validateSession(usernameVal, aIVal);
    } else {
      console.log("Not logged In");
      navigate("/auth");
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={6} md={4}>
          <Image
            src="https://www.avinasoft.com/assets/img/logo.png"
            roundedCircle
          />
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      </Row>

      {show === true && (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{showMsgTitle}</Alert.Heading>
          <p>{showMsg}</p>
        </Alert>
      )}
    </Container>
  );
}

export default SplashScreen;
