export const client = {
  domain: "https://api.erp.avinasoft.in",
  //domain: "http://127.0.0.1:7409",
  webPath: "/api/web",
  corePath: "/api/core",
  appPath: "/api/app",
  loginPath: "/",
  // signUpPath :  "/dashboard",
  // taskViewPath : "/taskView",
  // taskAddPath : "/taskAdd",
  // userViewPath : "/userView",
  // userAddPath : "/userAdd",
  // staffViewPath : "/staffView",
  // staffAddPath : "/staffAdd",
  // billedInViewPath : "/billedInView",
  // billedInAddPath : "/billedInAdd",
  // changePasswordPath : "/changePassword",
  // forgotpasswordPath : "/forgotpassword",
  headers: {
    "Content-Type": "application/json",
    //Origin: "https://api.erp.avinasoft.in",
  },
};

export const regexAlphabets = /^[A-Za-z]+$/;

export const regexAlphaNumeric = /^[a-zA-Z0-9_]+$/;
