import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View ,Badge} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import {
  BsFillPenFill,
  BsFillTrashFill,
  BsFillArrowLeftCircleFill,
  BsArrowLeftCircle,
} from "react-icons/bs";

const AddUpdateDispatch = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);
  var dispatchArr = [
    {
      SNo: 1,
      patientId: "BS0001",
      patientName: "Raj",
      studyId: "ST001",
      studyName: "Abdomemn Scan",
      charges: "Rs 3000",
      payment: "Paid",
      checkedState: false,
    },
    {
      SNo: 2,
      patientId: "BS0002",
      patientName: "Arul",
      studyId: "ST002",
      studyName: "Neck scan",
      charges: "Rs 1000",
      payment: "Unpaid",
      checkedState: false,
    },
    {
      SNo: 3,
      patientId: "BS0003",
      patientName: "Joel",
      studyId: "ST003",
      studyName: "Ultra Scan",
      charges: "Rs 2000",
      payment: "Paid",
      checkedState: false,
    },
  ];

  let initialItems = [];

  const intialValues = {
    MobileNumber: "",
    patientId: "",
    age: "",
    PatientName: "",
    address1: "",
    address2: "",
    district: "",
    pincode: "",

    refDoctor: "",
    charges: "",
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);
  var validateErr = false;

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);

  const [dispatchList, setDispatchList] = useState(dispatchArr);

  const [selectedIdx, setSelectedIdx] = useState();
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [searchSuccess, setSearchSuccess] = useState(false);
  const [patientSelected, setPatientSelected] = useState(false);

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdatereceipt called ##########################"
      );
      /*
      if (state === null) {
        navigate("/");
      } else {
        console.log("PASSED STATE VALUE IN Doctor Add/Update page : ", state);
      }
      */
      initialized.current = true;
    }
  }, []);

  const handleValueChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    if (!values.patientId) {
      errors.patientId = "Patient Id  is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.studyId) {
      errors.studyId = "Study Id  is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.mobileNumber) {
      errors.mobileNumber = "Mobile number is required";
      validateErr = true;
      setValidateError(true);
    }

    if (values.mobileNumber.length != 10) {
      errors.mobileNumber = "Mobile number should be 10 digits";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.patientName) {
      errors.patientName = "Patient Name  is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.patientName) {
      errors.patientName = "Patient Name  is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.age) {
      errors.age = "Age  is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.charge) {
      errors.charge = "Charge  is required";
      validateErr = true;
      setValidateError(true);
    }

    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ",
      validateError
    );
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      console.log("LINE ITEMS : ", items);
      console.log("Form submit called ...");

      console.log("patient Name : ", formValues["name"]);
    }
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit  called : ", e);
    setIsReadOnly(false);
  };

  const handleDelete = async (e) => {
    console.log("Handle delete  called : ", e);
  };

  const handleSave = async (e) => {
    console.log("Handle Click save called : ", e);
  };

  const handleReset = async (e) => {
    console.log("Handle click Reset called : ", e);
    setSearchSuccess(false);
    setPatientSelected(false);
  };

  const handleClearSearch = async (e) => {
    e.preventDefault();
    console.log("Handle click  Clear Search called : ", e);
    setSearchSuccess(false);
    setPatientSelected(false);
  };
  const handleNewStudy = async (e) => {
    console.log("handle new study clicked : ", e);
    setPatientSelected(true);
    setSearchSuccess(false);
  };
  const handleSearch = async (e) => {
    console.log("Handle Click search called : ", e);

    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErrSearch = false;
    setFormErrorsSearch(validateSearch(formValuesSearch));
    setIsSubmitSearch(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ",
      validateErrorSearch
    );

    console.log("^^^^^^^^^^^^ IS submit search : ", isSubmitSearch);

    setSearchSuccess(true);
  };

  const dispatchRowClick = async (e, id) => {
    console.log("Dispatch row clicked : ", e);
    console.log("Clicked at : ", id.idx);
  };

  var today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  var studyDateStr = `${date}-${month}-${year}`;
  const handleClickSave = async (e) => {
    console.log("Handle Click save called : ", e);
  };
  const intialValuesSearch = {
    searchMobile: "",
  };
  const [formValuesSearch, setFormValuesSearch] = useState(intialValuesSearch);
  const [formErrorsSearch, setFormErrorsSearch] = useState({});
  const [validateErrorSearch, setValidateErrorSearch] = useState(false);
  var validateErrSearch = false;
  const [isSubmitSearch, setIsSubmitSearch] = useState(false);

  const validateSearch = (values) => {
    console.log("validateErr before start processing SEARCH : ", validateErr);
    console.log("Validate called SEARCH ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    /*
    if (!values.searchMobile) {
      errors.searchMobile = "Search Mobile number is required";
      validateErrSearch = true;
      setValidateErrorSearch(true);
    }

    if (values.searchMobile.length != 10) {
      errors.searchMobile = "Search Mobile number should be 10 digits";
      validateErrSearch = true;
      setValidateErrorSearch(true);
    }
    */

    console.log("BEFORE RETURN validate errors ...", validateErrorSearch);
    console.log("VALIDATE ERROR inside validate func : ", validateErrSearch);
    return errors;
  };

  return (
    <>
      <Container>
        <Sidebar2 />
        <Form onSubmit={handleSearch}>
          <Row>
          <Col xs={4} md={4} sm={4} lg={4}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>Dispatch</span>
            </Col>  
          </Row>

          <Row>
            <Col xs={2} md={2} sm={2} lg={2}>
            <Form.Group className="mb-3"> 
               <Form.Control
                type="number"
                name="mobile_number"
                className="mobNum"
                id="search-mobile-id"
                placeholder="Patient Mobile"
                value={formValues.mobile_number}
                onChange={(e) => handleValueChange(e)}
              />
              </Form.Group>
              &nbsp;&nbsp;
              <p className="error-msg">{formErrors.mobile_number}</p>
              &nbsp;&nbsp; &nbsp;&nbsp;
            </Col>

            <Col xs={2} md={2} sm={2} lg={2}>
            <Form.Group className="mb-3"> 
               <Form.Control
                type="text"
                name="first_name"
                id="search-name-id"
                placeholder="Patient Name"
                value={formValues.first_name}
                onChange={(e) => handleValueChange(e)}
              />
              </Form.Group>
              <p className="error-msg">{formErrors.first_name}</p>
              &nbsp;&nbsp;
            </Col>
            <Col xs={4} md={4} sm={4} lg={4}>
              &nbsp;&nbsp;
              <Button id="btn-search-id" name="btn-search-name" type="submit">
                Search
              </Button>
              &nbsp;&nbsp;
              <Button
                variant="secondary"
                id="btn-search-id"
                name="btn-search-name"
                onClick={handleClearSearch}
              >
                Clear Search
              </Button>
            </Col>
          </Row>
        </Form>
        {/*
        <Row style={{ height: props.height }}>
          <hr />
        </Row>
  */}
        <br></br>
        <br></br>

        {searchSuccess && (
          <div className="ui-form">
            <Row>
              <h3> Studies </h3>
            </Row>
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="table-primary">
                  <th className="table-row-heading">...</th>
                  <th className="table-row-heading"key={0}>S.No</th>
                  <th className="table-row-heading">Patient Id</th>
                  <th className="table-row-heading">Patient Name</th>
                  <th className="table-row-heading">Study Id</th>
                  <th className="table-row-heading">Study Name</th>
                  <th className="table-row-heading">Charges</th>
                  <th className="table-row-heading">Payment</th>
                </tr>
              </thead>
              <tbody>
                {dispatchList.map((dispatch, idx) => (
                  <tr
                    key={`dispatch-tr-key-${idx}`}
                    onClick={(e, id = { idx }) => dispatchRowClick(e, id)}
                    id={`dispatch-tr-id-${idx}`}
                    name={`dispatch-tr-name-${idx}`}
                    charges={`dispatch-tr-charges-${idx}`}
                    payment={`dispatch-tr-payment-${idx}`}
                  >
                    <Form.Check
                      name="study"
                      type="radio"
                      checked={dispatch.checkedState}
                    ></Form.Check>
                    <td key={idx}>{idx + 1}</td>
                    <td>{dispatch.patientId}</td>
                    <td>{dispatch.patientName}</td>
                    <td>{dispatch.studyId}</td>
                    <td>{dispatch.studyName}</td>
                    <td>{dispatch.charges}</td>
                    <td>{dispatch.payment}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Row className="justify-content-center">
              <Col xs={4} sm={4} md={4} lg={4}>
                <Button variant="primary" onClick={handleNewStudy}>
                  Enter Dispatch details
                </Button>
              </Col>
            </Row>
          </div>
        )}
        {patientSelected && (
          <div className="Container">
            
            <Row>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h4 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Bill Details
                    </Badge>
                  </h4>
                </Col>
              </Row>
              

            <div className="ui-form">
              <Row>
                <Col xs={1} md={1} sm={1} lg={1}>
                  <Form.Label >Patient Id:</Form.Label >
                </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                  <p>BS0002</p>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Label>Patient Name:</Form.Label>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <p>Arul</p>
                </Col>
              </Row>
              <Row>
                <Col xs={1} md={1} sm={1} lg={1}>
                  <Form.Label>Study Id:</Form.Label>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                  <p>ST002</p>
                </Col>

                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Label>Study Name:</Form.Label>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <p>Neck Scan</p>
                </Col>

                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Label>Study Date:</Form.Label>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <p>{studyDateStr}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={1} md={1} sm={1} lg={1}>
                  <Form.Label>Charges:</Form.Label>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                  <p>Rs 1000</p>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <Form.Label>Payment Status:</Form.Label>
                </Col>
                <Col xs={2} md={2} sm={2} lg={2}>
                  <p>Paid</p>
                </Col>
              </Row>

             
              <Row>
                <Col xs={6} md={6} sm={6} lg={6}>
                  <h4 style={{ color: "#4d4d4d" }}>
                    <Badge pill bg="secondary">
                      Dispatch Details
                    </Badge>
                  </h4>
                </Col>
              </Row>
               
              <Row>
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Received By</Form.Label>
                  <Form.Control
                    type="text"
                    name="received_by"
                    id="received_by"
                    placeholder="Received by"
                    value={formValues.receivedBy}
                    readOnly={isReadOnly}
                  /> 
                  </Form.Group>
                </Col>

                <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Mobile</Form.Label>
                  <Form.Control
                    type="number"
                    name="mobileNumber"
                    placeholder="Mobile"
                    value={formValues.mobileNumber}
                    readOnly={isReadOnly}
                  />
                  </Form.Group>
                </Col>
                <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Relationship to Patient</Form.Label>
                  <Form.Control
                    type="text"
                    name="relationtopatient"
                    placeholder="Relationship to Patient"
                    value={formValues.relationtopatient}
                    readOnly={isReadOnly}
                  />
             </Form.Group>
                </Col>
              </Row>
              
              <Row>
              <Col xs={4} md={4} sm={4} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Note</Form.Label>
                  <Form.Control
               
                    type="text"
                    name="note"
                    placeholder="Note"
                    className="mobNum"
                    value={formValues.note}
                    width="25"
                    readOnly={isReadOnly}
                  /></Form.Group>
                </Col>
              </Row>
             
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  style={{ disply: "flex", justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                  <Button
                      variant="secondary"
                      id="Reset"
                      onClick={(e) => handleReset(e)}
                    >
                      Clear
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      type="submit"
                      id="save"
                      onClick={(e) => handleSave(e)}
                      //disabled={submitAction ? true: false}
                    >
                    Save
                    </Button>
                  </div>
                </Col>
                
              </Row>
           

             
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default AddUpdateDispatch;
