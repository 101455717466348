import logo from "./logo.svg";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";

import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
//import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import Sidebar2 from "./pages/Sidebar2";
import SplashScreen from "./pages/SplashScreen";
import Footer from "./pages/Footer";
import ListPatients from "./pages/ListPatients";
import AddUpdatePatient from "./pages/AddUpdatePatient";
import ListDoctors from "./pages/ListDoctors";
import AddUpdateDoctor from "./pages/AddUpdateDoctor";
import ListServices from "./pages/ListServices";
import AddUpdateService from "./pages/AddUpdateService";

import ListServiceTrans from "./pages/ListServiceTrans";
import AddUpdateServiceTrans from "./pages/AddUpdateServiceTrans";
import ReportServiveTrans from "./pages/ReportServiceTrans";
import ReportServiveCharges from "./pages/ReportServiceCharges";
import ListUsers from "./pages/ListUsers";
import AddUpdateUser from "./pages/AddUpdateUser";
import AddUpdateReceipt from "./pages/AddUpdateReceipt";
import AddUpdateDispatch from "./pages/AddUpdateDispatch";
import AddUpdateServiceTransV2 from "./pages/AddUpdateServiceTransV2";
import AddUpdateServiceTransV3 from "./pages/AddUpdateServiceTransV3";
import AddUpdateProfile from "./pages/AddUpdateProfile";
import AddUpdatePassword from "./pages/AddUpdatePassword";
import AddUpdateResetPassword from "./pages/AddUpdateResetPassword";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/listPatients" element={<ListPatients />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/addUpdatePatient" element={<AddUpdatePatient />} />
        <Route path="/listDoctors" element={<ListDoctors />} />
        <Route path="/addUpdateDoctor" element={<AddUpdateDoctor />} />

        <Route path="/listServices" element={<ListServices />} />
        <Route path="/addUpdateService" element={<AddUpdateService />} />

        <Route path="/listServiceTrans" element={<ListServiceTrans />} />
        <Route
          path="/addUpdateServiceTrans"
          element={<AddUpdateServiceTrans />}
        />
        <Route path="/reportServiveTrans" element={<ReportServiveTrans />} />

        <Route
          path="/reportServiveCharges"
          element={<ReportServiveCharges />}
        />
        <Route path="/listUsers" element={<ListUsers />} />
        <Route path="/addUpdateUser" element={<AddUpdateUser />} />
        <Route path="/addUpdateReceipt" element={<AddUpdateReceipt />} />
        <Route path="/addUpdateDispatch" element={<AddUpdateDispatch />} />
        <Route path="/addUpdateServiceTransV2" element={<AddUpdateServiceTransV2 />}/>
        <Route path="/addUpdateServiceTransV3" element={<AddUpdateServiceTransV3 />}/>
        <Route path="/addUpdateProfile" element={<AddUpdateProfile />} />
        <Route path="/addUpdatePassword" element={<AddUpdatePassword />} />
        <Route path="/addUpdateResetPassword" element={<AddUpdateResetPassword />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
