//import "bootstrap/dist/css/bootstrap.min.css"
import React, { Fragment, useState } from "react";

import { client } from "../config/Config";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
//import Nav from 'react-bootstrap/Nav';
import { Nav, NavItem, Navbar, NavDropdown, Dropdown } from "react-bootstrap";
//import { Glyphicon } from '../GlyphiconToFa';
//import { RBGlyphicon } from 'react-bootstrap';
import { ArrowRight } from "react-bootstrap-icons";
import Offcanvas from "react-bootstrap/Offcanvas";

//import "bootstrap/dist/css/bootstrap.min.css"
//import 'bootstrap/dist/css/bootstrap.min.css';
import "../App.css";

import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import logo_avn from "../assets/images/logo_avn.png";

function Auth() {
  const [justifyActive, setJustifyActive] = useState("tab1");
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Unable to login");
  const [showMsgTitle, setShowMsgTitle] = useState("Login FAILED!");
  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }

    setJustifyActive(value);
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    // Prevent page reload
    event.preventDefault();

    console.log("Form submit clicked ...");

    console.log("DATA : ", document.forms[0]);

    var { username, password } = document.forms[0];
    console.log("username : ", username.value);
    console.log("pasword : ", password.value);

    let reqLoginPayload = {
      username: username.value,
      password: password.value,
    };

    var headers = client.headers;
    console.log("payload:", reqLoginPayload);

    try {
      var res = await axios.post(
        client.domain + "/login",
        reqLoginPayload,
        { headers },
        { withCredentials: true }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server : ",
          res.data.detail.res_data
        );
        console.log(
          "Response message from server userInfo : ",
          res.data.detail.res_data.user_info
        );
        console.log(
          "Response message from server aI : ",
          res.data.detail.res_data.aI
        );

        localStorage.setItem("_aI", res.data.detail.res_data.aI);
        //localStorage.setItem('userRoleId', userRoleId);
        localStorage.setItem(
          "username",
          res.data.detail.res_data.user_info.username
        );

        navigate("/addUpdateServiceTransV3");
      }
      /*
          if (res.data.status === "SUCCESS" ) {
            this.setState ({
            tokenJwt: res.data.res_data.token,
            isLoaded: true,
            openOTP: true,
            })
              // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
          }
          else {
          }
        */
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg(error.response.data.detail.res_data);
          setShowMsgTitle("LOGIN FAILED!");
          setShow(true);
        }
      }
    }
  };

  return (
    <Fragment>
      <div
        style={{ marginTop: 150, alignItems: "center", textAlign: "center" }}
      >
        <div>
          <img src={logo_avn} height="10%" width="10%" alt="Avinasoft" />
          <label
            style={{
              fontFamily: "Helvetica-Bold",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Bharath Scans
          </label>
        </div>
        {/*
    <Navbar key="sm" className="bg-body-tertiary mb-3 justify-content-center" style={{ alignItems: 'center', textAlign: 'center', width:'30%', marginLeft: '40%'}}>
            
          <Container >
            <Navbar.Brand href="/">
            <img src={logo_avn} height="64" width="64" alt="Avinasoft"/>
            </Navbar.Brand>

            <Navbar.Brand href="#">KMC ReadyMix</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-sm`}
              aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
              placement="end"
            >

            </Navbar.Offcanvas>
            </Container>
            </Navbar>
  */}
        <div className="Auth-form-container">
          <form className="Auth-form" onSubmit={handleSubmit}>
            <div className="Auth-form-content">
              <h3 className="Auth-form-title">Sign In</h3>
              <div className="form-group mt-3">
                <label>Username</label>
                <input
                  type="text"
                  name="username"
                  className="form-control mt-1"
                  placeholder="Enter username"
                />
              </div>
              <div className="form-group mt-3">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  className="form-control mt-1"
                  placeholder="Enter password"
                />
              </div>
              <div className="d-grid gap-2 mt-3">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
              <p className="forgot-password text-right mt-2">
                Forgot <a href="#">password?</a>
              </p>
            </div>

            {show === true && (
              <Alert
                variant="danger"
                onClose={() => setShow(false)}
                dismissible
              >
                <Alert.Heading>{showMsgTitle}</Alert.Heading>
                <p>{showMsg}</p>
              </Alert>
            )}
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default Auth;
