import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import DateTimePicker from 'react-datetime-picker';

import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
  BsCurrencyRupee,
  BsFillEnvelopePlusFill
} from "react-icons/bs";


import axios from "axios";
import { client } from "../config/Config";

const ReportServiveCharges = (props) => {
  //function ListPatients(props) {
  const initialized = useRef(false);
  const navigate = useNavigate();

  const intialValues = {
    id:0,
    doctorId: 0,
    patientId: 0,
    periodFrom: new Date(),
    periodTo: new Date()
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);
  
  var servicesTransArr = [
    {
      id: 1,
      date: "2023-08-23",
      study_id: "BS0001",
      study_name: "Ultra Scan",
      patient_name: "Muthusamy"
    },
    {
      id: 2,
      date: "2023-08-23",
      study_id: "BS0002",
      study_name: "Abdomen Scan",
      patient_name: "Chelladurai"
    },
    {
      id: 3,
      date: "2023-08-23",
      study_id: "BS0003",
      study_name: "Neck Scan",
      patient_name: "Anand"
    }
  ];


  var reportServiceTransArr = [
    {name: "Total Bills issued", value: "80"},
    {name: "Total Charges", value: "Rs 800"},
    {name: "Total payment received", value: "Rs 700"},
    {name: "Total payment pending", value: "Rs 100"},
  ];
  const [reportServiceTransList, setreportServiceTransList] = useState(reportServiceTransArr);

  const [servicesTransList, setServicesTransList] = useState(servicesTransArr)
  //navigate("/addUpdatePatient");

  
  const handleView = (e) => {
    e.preventDefault();
    console.log("Handle View service called : ", e);

    //const { id } = e.viewportElement;
    //console.log("Captured edit element name : ", name);
    //console.log("Captured edit element value : ", value);
    //console.log("Captured edit element id : ", id);

    //var currentRowId = id.substring(id.lastIndexOf("-") + 1);
    //var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    //console.log("Name of element : ", nameOfElement);
    //console.log("Current row id : ", currentRowId);

    //console.log("Currently selected row value : ", serviceList[currentRowId]);

    
    navigate("/addUpdateServiceTrans", {
      state: [],
    });
    
  };

  const handleAdd = async(e) => {
    console.log("handleAdd clicked : ", e);

    navigate("/addUpdateServiceTrans", {
      state: [],
    });
  }

  const handlePay = async(e) => {
    console.log("Handle pay clicked : ", e);
  }

  const toSqlDatetime = (inputDate) => {
    const date = new Date(inputDate)
    const dateWithOffest = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
    return dateWithOffest.toISOString().slice(0, 19).replace('T', ' ')
  }
  

  const onChangeStartDate = (e) => {
    console.log("Date onChange start called ...", e);
    var formattedDt = toSqlDatetime(e);

    setFormValues({...formValues, ["periodFrom"]: formattedDt});
    
    console.log("Formatted str DC date : ", formattedDt);
  }

  const onChangeEndDate = (e) => {
    console.log("Date onChange end called ...", e);
    var formattedDt = toSqlDatetime(e);

    setFormValues({...formValues, ["periodTo"]: formattedDt});
    
    console.log("Formatted str DC date : ", formattedDt);
  }

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListPatients called ##########################"
      );

      initialized.current = true;
    }
  }, []);

  const handleAddService = async (e) => {
    console.log("handle add patients called : ", e);

    navigate("/addUpdateServiceTran", {
      state: [],
    });
  };

  

  return (
    <Container>
      <Sidebar2 />
      <div className="container">

        <Row>
        <Col xs={2} md={2} sm={2}>
        <div className="field">
          <label>From :</label>
          </div>
        </Col>
        <Col xs={4} md={4} sm={4}>
        <div className="field">
          <DateTimePicker onChange={onChangeStartDate} calendarIcon={null} format="dd-MM-y HH:mm:ss" value={formValues.periodFrom} />
        </div>
        </Col>

        <Col xs={2} md={2} sm={2}>
        <div className="field">
          <label>To :</label>
          </div>
        </Col>
        <Col xs={4} md={4} sm={4}>
        <div className="field">
          <DateTimePicker onChange={onChangeEndDate} calendarIcon={null} format="dd-MM-y HH:mm:ss" value={formValues.periodTo} />
        </div>
        </Col>
        </Row>

        <br></br>
        <br></br>

        <table className="table table-bordered table-striped">
          <tbody>
            {reportServiceTransList.map((reportServiceTran, idx) => (
              <tr>
                <td class="w-25">{reportServiceTran.name}</td>
                <td  class="w-10">{reportServiceTran.value}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <br></br>
      </div>
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ReportServiveCharges;
