import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Table,
  Alert,
  Modal,
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
} from "react-icons/bs";

import { BsFillEnvelopePlusFill } from "react-icons/bs";

import axios from "axios";
import { client } from "../config/Config";



const ListDoctors = (props) => {
  //function ListDoctors(props) {
  const initialized = useRef(false);
  const navigate = useNavigate();

  var doctorsArr = [
    {
      id: 1,
      name: "Abi",
      mobileNumber: "9942410747",
      hospital: "Gokul Hospital",
      activeState: true,
    },
    {
      id: 2,
      name: "John",
      mobileNumber: "8870886958",
      hospital: "CNS Hospital",
      activeState: false,
    },
    {
      id: 3,
      name: "Ram",
      mobileNumber: "9787453372",
      hospital: "Aadhar Hospital",
      activeState: true,
    },
  ];

  const [doctorsList, setDoctorsList] = useState(doctorsArr);
  const [toRender, setToRender] = useState(false);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );

  const [selectedId, setSelectedId] = useState(-1);

  const [showDialog, setShowDialog] = useState(false);

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const updateDoctorStatus = async (selectedId) => {
    var selectedObj = doctorsList[selectedId];
    let reqStatusUpdatePayload = {
      physician_id: selectedObj.physician_id,
      active: !selectedObj.active,
    };

    var headers = client.headers;
    console.log("payload:", reqStatusUpdatePayload);

    try {
      var res = await axios.post(
        client.domain + "/dcl/activatePhysician",
        reqStatusUpdatePayload,
        { headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        //console.log("Response message from server : ", res.data.detail.res_data);
        //console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        //console.log("Response message from server aI : ", res.data.detail.res_data.aI);
        // Remove login session from localstorage
        //localStorage.removeItem("username");
        //localStorage.removeItem("_aI")
        //navigate("/auth");

        var itemSelectedUpdate = doctorsList[selectedId];
        itemSelectedUpdate.active = !itemSelectedUpdate.active;
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED MODIFIED ********** ",
          itemSelectedUpdate
        );

        const newitems = doctorsList.slice();
        newitems[selectedId] = itemSelectedUpdate;
        setDoctorsList(newitems);

        setShowDialog(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle("LOGIN FAILED!");
          //setShow(true);
        }
      }
    }
    setShowDialog(false);
  };

  const handleSwitchAction = () => {
    console.log("user confirmed to enable or disable ...");
    console.log("user selected id for action is : ", selectedId);

    // call update API
    updateDoctorStatus(selectedId);
  };

  //navigate("/addUpdatePatient");

  const getDoctorsList = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {
        ...client.headers,
        Authorization: `Bearer ${aIVal}`
      }
      var headers = client.headers;

      var res = await axios.get(
        client.domain + "/dcl/getAllPhysicians",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log("Response message from server : ", res.data.detail.data);
        setDoctorsList(res.data.detail.data);
        setToRender(true);
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        }
      }
    }
  };

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListDoctors called ##########################"
      );
      getDoctorsList();
      initialized.current = true;
    }
  }, []);

  const handleAdd = async (e) => {
    console.log("handle add doctors called : ", e);
    navigate("/addUpdateDoctor", {
      state: { passed_element: [], action: "add" },
    });
  };

  const handleView = async (e) => {
    console.log("handle edit doctors called : ", e);

    var selectedTagId = e.target.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    if (selectedTagId) {
      var currentId = selectedTagId.substring(
        selectedTagId.lastIndexOf("-") + 1
      );
      var nameOfElement = selectedTagId.substring(
        0,
        selectedTagId.lastIndexOf("-")
      );
      console.log("Name of element VIEW: ", nameOfElement);
      console.log("Current id VIEW : ", currentId);

      console.log(
        "<<<<<<<<<<<<<<<<< ITEM SELECTED VIEW ********** ",
        doctorsList[currentId]
      );
      navigate("/addUpdateDoctor", {
        state: { passed_element: doctorsList[currentId], action: "view" },
      });
    }
  };

  const handleOnChange = async (e) => {
    console.log("handle on change called : ", e);

    var selectedTagId = e.target.id;
    console.log("SELECTED TAG ID: ", selectedTagId);
    var currentId = selectedTagId.substring(selectedTagId.lastIndexOf("-") + 1);
    var nameOfElement = selectedTagId.substring(
      0,
      selectedTagId.lastIndexOf("-")
    );
    console.log("Name of element : ", nameOfElement);
    console.log("Current id : ", currentId);

    console.log(
      "<<<<<<<<<<<<<<<<< ITEM SELECTED ********** ",
      doctorsList[currentId]
    );

    setSelectedId(currentId);

    setShowDialog(true);
    /*
    var itemSelected = doctorsList[currentId];
    itemSelected.active = !itemSelected.active;
    console.log(
      "<<<<<<<<<<<<<<<<< ITEM SELECTED MODIFIED ********** ",
      itemSelected
    );

    const newitems = doctorsList.slice();
    newitems[currentId] = itemSelected;

    setDoctorsList(newitems);
    */
  };

  return (
    <Container>
      <Sidebar2 />
      {toRender && (
        <div className="container">
         <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <h4>Doctors</h4>
            </Col>
            <Col xs={6} md={6} sm={6} lg={6}>
              <Button
                variant="warning"
                id="add-btn-id"
                name="add-btn-name"
                className=" float-end"
                onClick={(e) => handleAdd(e)}
              >
                New Doctor
              </Button>
            </Col>
          </Row>
          <hr align="center" />
          <div className="ui-divider"></div>

          <Table striped bordered hover size="sm">
            <thead>
              <tr className="table-primary">
                <th
                  className="table-row-heading"
                  key={"doctor-list-heading-key"}
                >
                  S.No
                </th>
                <th className="table-row-heading">Name</th>
                <th className="table-row-heading">Mobile</th>
                <th className="table-row-heading">Hospital</th>
                <th className="table-row-heading">Active</th>
                <th className="table-row-heading">Details</th>
              </tr>
            </thead>
            <tbody>
              {doctorsList.map((doctor, idx) => (
                <tr key={`doctor-table-row-id-${idx}`}>
                  <td key={`doctor-list-item-key-${idx}`}>{idx + 1}</td>
                  <td key={`doctor-list-name-key-${idx}`}>
                    {doctor.physician_name}
                  </td>
                  <td key={`doctor-list-mobile-number-key-${idx}`}>
                    {doctor.mobile_primary}
                  </td>
                  <td key={`doctor-list-hospital-key-${idx}`}>
                    {doctor.hospital}
                  </td>
                  <td>
                    <Form.Check // prettier-ignore
                      key={`doctor-switch-key-${idx}`}
                      type="switch"
                      id={`doctor-switch-id-${idx}`}
                      name={`doctor-switch-name-${idx}`}
                      onChange={(e) => handleOnChange(e)}
                      checked={doctor.active}
                    />
                  </td>
                  <td>
                    <BsCardText
                      key={`view-icon-doctor-key-${idx}`}
                      size={28}
                      style={{ color: "#0D6EFD", cursor: "pointer" }}
                      onClick={handleView}
                      name={`view-icon-doctor-name-${idx}`}
                      id={`view-icon-doctor-id-${idx}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <br></br>
        </div>
      )}
      {show === true && (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{showMsgTitle}</Alert.Heading>
          <p>{showMsg}</p>
        </Alert>
      )}
      <Modal
        show={showDialog}
        onHide={handleCloseDialog}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm enable/disable</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to confirm your actions?.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSwitchAction}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListDoctors;
