import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button, View } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { withRouter } from "react-router";
import Sidebar2 from "./Sidebar2";
import "../Dashboard.css";
import "../App.css";
//import Card from 'react-bootstrap/Card';
//import ListGroup from "react-bootstrap/ListGroup";
//import Badge from "react-bootstrap/Badge";
//import DateTimeField from "react-bootstrap-datetimepicker"
//import DateTimePicker from "react-datetime-picker";

import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

import Select from "react-select";

import { client } from "../config/Config";
import axios from "axios";

import { useLocation } from "react-router-dom";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import {
  BsFillPenFill,
  BsFillTrashFill,
  BsFillArrowLeftCircleFill,
  BsArrowLeftCircle,
  BsPencilSquare
} from "react-icons/bs";

import DateTimePicker from "react-datetime-picker";

const AddUpdateUser = (props) => {
  const initialized = useRef(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  //console.log("PASSED STATE VALUE IN Patient Add/Update page : ", state);

  let initialItems = [];

  const optionsRole = [
    { value: 1, label: "Admin" },
    { value: 2, label: "SuperAdmin" },
    { value: 3, label: "Staff" },
  ];
  const intialValues = {
    name: "",
    roleId: 0,
    password: "",
    confirmPassword: "",
    locked: false,
  };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [validateError, setValidateError] = useState(false);

  const [roleIdSelect, setRoleIdSelect] = useState({});
  var validateErr = false;

  const [isSubmit, setIsSubmit] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [empIdSelect, setEmpIdSelect] = useState({});
  const [optionsEmp, setOptionsEmp] = useState([]);
  const [submitAction, setSubmitAction] = useState(false);

  /*
  const getUserRoleList = async() => {
    console.log("getRoleList method called ...");
    var retState = false;
    var userRoleArr = [];
 
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/core/getUserRoles",
        { headers },
        { withCredentials: false }
      );
      
      console.log("Response from server getUserRoleList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        
        if (res.data.detail.data.length > 0) {
          //var empRoleInd = {};
          
          res.data.detail.data.map((userRole) => {
            console.log("User role ind data : ", userRole);
            var userRoleInd = {};
            userRoleInd.value = userRole.id;
            userRoleInd.label = userRole.name;
            userRoleArr.push(userRoleInd);
          
          })
          console.log("user Role Arr : ", userRoleArr);        
         
        }
        retState = true;
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
         
        }
      }
    
    }
    return [retState, userRoleArr];
  }
  */

  /*
  const getEmpList = async() => {
    console.log("getEmpList method called ...");
    var retState = false;
    var empArr = [];
 
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers, Authorization: `Bearer ${aIVal}`}
      var res = await axios.get(
        client.domain + "/hrms/getEmployees",
        { headers },
        { withCredentials: false }
      );
     
      console.log("Response from server getEmpList : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        
        if (res.data.detail.data.length > 0) {
          //var empRoleInd = {};
          
          res.data.detail.data.map((emp) => {
            console.log("Employee ind data : ", emp);
            var empInd = {};
            empInd.value = emp.id;
            empInd.label = emp.employee_name;
            empArr.push(empInd);
          
          })
          console.log("Employee Arr : ", empArr);        
         
        }
        retState = true;
      }
      
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          
        }
      }
    
    }
    return [retState, empArr];
  }
  console.log("formValues : " , formValues);
  */

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::addUpdateService called ##########################"
      );
      if (state === null) {
        navigate("/listUsers");
      } else {
        console.log("PASSED STATE VALUE IN User Add/Update page : ", state);
        //setFormValues(state.passed_element);
      }
      console.log("PASSED STATE VALUE IN Service Add/Update page : ", state);
      initialized.current = true;
    }
  }, []);
    /*
    const callUpdateApi = async () => {
    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {...client.headers,Authorization: `Bearer ${aIVal}`}
      var apiToCall = "";
      if (state.action === "add") {
        apiToCall = "/core/addUser";
      } else if (state.action === "view") {
        apiToCall = "/core/updateUser";
      } else {
        apiToCall = "/core/addUser";
      }
      
      var lockStatus = false;
      if ( formValues["lock_status"] === 'YES') {
        lockStatus = true;
      }
     
      var reqParam = {
        id: formValues["id"],
        username: formValues["username"],
        user_role_id:userRoleIdSelect.value,
        employee_id: empIdSelect.value, 
        is_locked: lockStatus
      }

      if ( state.action === 'add' ) {
        reqParam.password = formValues["password"];
      }
   
     
      var res = await axios.post(client.domain + apiToCall, reqParam, { headers },{ withCredentials: false });
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        setShowMsg("User Added successfully...!");
        setShowMsgTitle("Success");
        setShowDialog(true);
        setSubmitAction(false);
      }
      else {
        console.log("error data.message : ", res.data.detail.data.message);
        setShowMsg(res.data.detail.data.message);
        setShowMsgTitle("User creation failed");
        setShowDialog(true);
        setSubmitAction(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          
        }
      }
      setSubmitAction(false);
    }
  };
  */


  const handleOnChange = async (e, data) => {
    e.preventDefault();
    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;
    if ( state.action === 'add') {
    if (!values.userName) {
      errors.userName = "User name is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.password) {
      errors.password = "password is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.confirm_password) {
      errors.confirm_Password = "Confirm password is required";
      validateErr = true;
      setValidateError(true);
    }
    if (!values.confirm_password) {
      errors.confirm_password = "Confirm Password should not be empty";
      validateErr = true;
    }

    if ( values.password !== values.confirm_password ) {
      errors.password = "password and confirm password should be same";
      errors.confirm_password = "password and confirm password should be same";
      validateErr = true;
    }
  }

    console.log("BEFORE RETURN validate errors ...", validateError);
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  const handleRoleSelect = (e, data) => {
    console.log("handleRoleSelect called ...", e);
    setRoleIdSelect(e);

  };
  const handleEmpSelect = (e) => {
    console.log("handleEmpSelect called ...", e);
    setEmpIdSelect(e);
  };
  const handleValueChange = async (e) => {
    console.log("Value button clicked : ", e);

    const { name, id } = e.currentTarget;
    console.log("Captured value name : ", name);
    console.log("Captured value id : ", id);

    var currentRowName = id.substring(id.lastIndexOf("-") + 1);
    var nameOfElement = id.substring(0, id.lastIndexOf("-"));
    console.log("Name of element : ", nameOfElement);
    console.log("Current row id : ", currentRowName);
    setFormValues({ ...formValues, ["lock_status"]: currentRowName.toUpperCase() });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit called ...");
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& : ",
      validateError
    );
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
      console.log("LINE ITEMS : ", items);
      console.log("Form submit called ...");

      console.log("User Name : ", formValues["name"]);
    }
  };
  const handleSave = async (e) => {
    e.preventDefault();
    console.log("Handle Click save called : ", e);
    console.log("Formvalues : ", formValues);

    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    if (validateErr === false) {
      setSubmitAction(true);
      //callUpdateApi();
    }
  };
  const handleBack = async (e) => {
    console.log("handle back clicked : ", e);
    navigate(-1);
  };

  const handleEdit = async (e) => {
    console.log("Handle Edit  called : ", e);
    setIsReadOnly(false);
  };
  const handleClear = async (e) => {
    console.log("Handle Clear  called : ", e);
    navigate(0); 
  };
 
  const handleReset = async (e) => {
    console.log("handle reset clicked : ", e);
    navigate("/addUpdateResetPassword");
    /*
    if(state.passed_element.is_active === false){
      setShowMsgTitle("Alert!");
      setShowMsg("Please activate the user before reset password!")
      setShowDialog(true);
    }
    else{
    navigate("/addUpdateResetPassword", {state: {passed_element: {"username": state.passed_element.username, "user_id": state.passed_element.id}, action: 'view'}}); 
    }
    */
  };
  /*
  const handlePostSaveOk = () => {
    console.log("user confirmed to user added ...");
    setShowDialog(false);
    //navigate("/listUsers");
  };
  */
  return (
    <>
      <div className="container">
        <Sidebar2 />
        <Form>
        <Row>
            <Col xs={2} md={2} sm={2} lg={2}>  
            <span style={{ color: "#4d4d4d", fontWeight: "500", fontSize: "1.5rem",verticalAlign:"bottom" }}>User</span>
            { (state.action === 'view' || state.action === 'edit') &&
              <>
              &nbsp;&nbsp;
             
            <Button variant={state.passed_element.is_active ? "outline-success" : "outline-danger"} size="sm"><span style={{cursor: "default", fontWeight: "500"}}>{state.passed_element.status}</span></Button>
            </>
            }
            </Col>
            
            <Col
              xs={10}
              md={10}
              sm={10}
              lg={10}
              className="float-end justify-content-end"
            >
              <div className="d-flex justify-content-end">
                    <Button
                      style={{cursor: "default", fontWeight: "500"}}
                      variant="outline-info"
                      id="Reset"
                      size="sm"
                      onClick={(e) => handleReset(e)}
                    >
                      Reset Password
                    </Button>
                    &nbsp;&nbsp;
                <Button variant="warning" className="btn btn-warning btn-md" onClick={(e) => handleBack(e)}>
                  <BsArrowLeftCircle
                    key={`back-btn-patient-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                   
                    name={`back-icon-patient-name`}
                    id={`edit-icon-patient-id`}
                  />
                </Button>
                {(state.action === "edit" || state.action === "view") && (
                  <>
                &nbsp;&nbsp;
                <Button variant="primary" className="btn btn-primary btn-md"  onClick={(e) => handleEdit(e)}>
                  <BsPencilSquare
                    key={`edit-btn-user-key`}
                    size={24}
                    style={{ cursor: "pointer" }}
                    name={`edit-icon-paBiSolidEditAlttient-name`}
                    id={`edit-icon-user-id`}
                  />
                </Button>
                </>
                )}
               
              </div>
            </Col>
          </Row>
          <hr align="center" />
         
          
          <div className="ui-divider"></div>
          <div className="ui-form">
            <Row>
            <Col xs={3} md={3} sm={3} lg={3}>
                  <Form.Group className="mb-3" >
                  <Form.Label className="field required">Employee</Form.Label>
                  <Select
                    id="employee"
                    name="employee"
                    value={{
                      value: empIdSelect.value,
                      label: empIdSelect.label,
                    }}
                    options={optionsEmp}
                    defaultValue={{
                      value: empIdSelect.value,
                      label: empIdSelect.label,
                    }}
                    onChange={handleEmpSelect}
                    readOnly={state.action === 'add' ? isReadOnly : true}
                    isDisabled={state.action !== 'add' && true}
                   //disabled={isReadOnly}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.employee_name}</p>
              </Col>
            <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required"> User Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="username"
                    name="username"
                    placeholder="User Name"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["username"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.username}</p>
              </Col>
              </Row>
              
              <Row>
              
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label className="field required">Role</Form.Label>
                
                  <Select
                    id="user_role_name"
                    name="user_role_name"
                    value={{
                      value: roleIdSelect.value,
                      label: roleIdSelect.label,
                    }}
                    options={optionsRole}
                    defaultValue={{
                      value: roleIdSelect.value,
                      label: roleIdSelect.label,
                    }}
                    onChange={handleRoleSelect}
                    isDisabled={isReadOnly}
                  />
               
                </Form.Group>
                <p className="error-msg">{formErrors.user_role_name}</p>
              </Col>
            
              
              <Col xs={3} md={3} sm={3} lg={3}>
              <Form.Group className="mb-3">
                  <Form.Label className="field required">Locked</Form.Label>
                  <div key={`inline-radio`} className="mb-3">
                    <Form.Check
                      inline
                      label="YES"
                      name="lock_status"
                      type="radio"
                      id={`inline-radio-yes`}
                      checked="true"
                    />
                    <Form.Check
                      inline
                      label="NO"
                      name="lock_status"
                      type="radio"
                      id={`inline-radio-no`}
                    />
                  </div>
                </Form.Group>
                <p className="error-msg">{formErrors.lock_status}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Password</Form.Label>
                  <Form.Control
                    type="text"
                    id="password"
                    name="password"
                    placeholder="Password"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["password"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.password}</p>
                </Col>
                <Col xs={3} md={3} sm={3} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label className="field required">Confirm Password</Form.Label>
                  <Form.Control
                    type="text"
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    onChange={(e) => handleOnChange(e)}
                    readOnly={isReadOnly}
                    value={formValues["confirm_password"]}
                    style={{
                      border: !isReadOnly && "1px solid gray",
                    }}
                  />
                </Form.Group>
                <p className="error-msg">{formErrors.confirm_password}</p>
              </Col>
              </Row>
           
              <Row className="flex float-end">
              <Col
               xs={12}
               md={12}
               sm={12}
               lg={12}
               style={{ disply: "flex", justifyContent: "right" }}
             >
               <div className="d-flex justify-content-end">
               <Button
                   variant="secondary"
                   id="Reset"
                   onClick={(e) => handleClear(e)}
                 >
                   Clear
                 </Button>
                 &nbsp;&nbsp;
                 <Button
                   type="submit"
                   id="save"
                   onClick={(e) => handleSave(e)}
                   //disabled={submitAction ? true: false}
                 >
                  Clear
                 </Button>
               </div>
             </Col>
             
           </Row>
           {/*
           <Modal
              show={showDialog}
              onHide={handleCloseDialog}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title>{showMsgTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{showMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handlePostSaveOk}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal> 
                  */}  
          
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddUpdateUser;
