import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
  Modal,
  Table
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
} from "react-icons/bs";

import { BsFillEnvelopePlusFill } from "react-icons/bs";

import axios from "axios";
import { client } from "../config/Config";

const ListServices = (props) => {
  //function ListServices(props) {
  const initialized = useRef(false);
  const navigate = useNavigate();

  var serviceArr = [
    {
      id: 1,
      name: "Ultra Scan",
      active: true,
    },
    {
      id: 2,
      name: "Abdomemn Scan",
      active: true,
    },
    {
      id: 3,
      name: "Neck Scan",
      active: true,
    },
  ];

  //const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  var validateErr = false;

  const [isSubmit, setIsSubmit] = useState(false);

  const [servicesList, setServicesList] = useState([]);
  //const [servicesListOrig, setServicesListOrig] = useState(serviceArr);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);
  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    let validName = true;
    if (!values.name) {
      errors.name = "Service name is required";
      validateErr = true;

      validName = false;
    }
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };
  //navigate("/addUpdateService");
  const [serviceList, setServiceList] = useState(serviceArr);
  const [toRender, setToRender] = useState(false);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const updateServiceStatus = async (selectedId) => {
    var selectedObj = serviceList[selectedId];
    let reqStatusUpdatePayload = {
      service_id: selectedObj.service_id,
      active: !selectedObj.active,
    };

    var headers = client.headers;
    console.log("payload:", reqStatusUpdatePayload);

    try {
      var res = await axios.post(
        client.domain + "/dcl/activateService",
        reqStatusUpdatePayload,
        { headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        //console.log("Response message from server : ", res.data.detail.res_data);
        //console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        //console.log("Response message from server aI : ", res.data.detail.res_data.aI);
        // Remove login session from localstorage
        //localStorage.removeItem("username");
        //localStorage.removeItem("_aI")
        //navigate("/auth");

        var itemSelectedUpdate = serviceList[selectedId];
        itemSelectedUpdate.active = !itemSelectedUpdate.active;
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED MODIFIED ********** ",
          itemSelectedUpdate
        );

        const newitems = serviceList.slice();
        newitems[selectedId] = itemSelectedUpdate;
        setServiceList(newitems);

        setShowDialog(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle("LOGIN FAILED!");
          //setShow(true);
        }
      }
    }
    setShowDialog(false);
  };
  const handleSwitchAction = () => {
    console.log("user confirmed to enable or disable ...");
    console.log("user selected id for action is : ", selectedId);

    // call update API
    updateServiceStatus(selectedId);
  };

  //navigate("/addUpdateService");

  const getServiceLists = async () => {
    console.log("Loading initial service list from server ....");
    try {
      var headers = client.headers;

      var res = await axios.get(
        client.domain + "/dcl/getAllServices",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server service : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server service : ",
          res.data.detail.data
        );
        setServiceList(res.data.detail.data);
        //setServicesListOrig(res.data.detail.data);
        setToRender(true);
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        }
      }
    }
  };

  function handleDeleteService(e) {
    e.preventDefault();
    console.log("Handle Delete service called : ", e);

    const { name, value, id } = e.target;
    console.log("Captured delete element name : ", name);
    console.log("Captured delete element value : ", value);
    console.log("Captured delete element id : ", id);
  }

  const handleEditService = (e) => {
    e.preventDefault();
    console.log("Handle Edit service called : ", e);

    const { name, value, id } = e.target;
    console.log("Captured edit element name : ", name);
    console.log("Captured edit element value : ", value);
    console.log("Captured edit element id : ", id);

    if (id) {
      var currentRowId = id.substring(id.lastIndexOf("-") + 1);
      var nameOfElement = id.substring(0, id.lastIndexOf("-"));
      console.log("Name of element : ", nameOfElement);
      console.log("Current row id : ", currentRowId);

      console.log("Currently selected row value : ", serviceList[currentRowId]);
      navigate("/addUpdateService", {
        state: { passed_element: serviceList[currentRowId], action: "view" },
      });
    }
  };
  const handleView = (e) => {
    e.preventDefault();
    console.log("Handle View service called : ", e);

    const { id } = e.target;
    //console.log("Captured edit element name : ", name);
    //console.log("Captured edit element value : ", value);
    //console.log("Captured edit element id : ", id);

    if (id) {
      var currentRowId = id.substring(id.lastIndexOf("-") + 1);
      var nameOfElement = id.substring(0, id.lastIndexOf("-"));
      console.log("Name of element : ", nameOfElement);
      console.log("Current row id : ", currentRowId);

      console.log("Currently selected row value : ", serviceList[currentRowId]);

      navigate("/addUpdateService", {
        state: { passed_element: serviceList[currentRowId], action: "view" },
      });
    }
  };

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListServices called ##########################"
      );
      getServiceLists();
      initialized.current = true;
    }
  }, []);
  const handleAdd = async (e) => {
    console.log("handle add services called : ", e);

    navigate("/addUpdateService", {
      state: { passed_element: [], action: "add" },
    });
  };

  /*
  const handleSearch = async (e) => {
    e.preventDefault();
    console.log("handle search called : ", e);
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
    }

    / *
    const searchedElement = mobile_number.find(
      (ele) => ele.value === state.passed_element.mobile_number
    );
    * /
    if (formValues.name) {
      const searchedElement = serviceList.find(
        (ele) => ele.name === formValues.name
      );
      console.log("searched element : ", searchedElement);

      setServiceList([searchedElement]);
    }
  };
  */

  /*
  const handleClearSearch = (e) => {
    //e.preventDefault();
    console.log("handle  clear search called : ", e);
    //console.log("setServicesListOrig : ", servicesListOrig);
    //setServiceList(serviceArr);
    //console.log("setServicesList : ", servicesList);
    setServiceList(servicesListOrig);

    setFormValues(intialValues);
  };
  */

  /*
  const handleValueChange = (e) => {
    //e.preventDefault();

    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };
  */

  const handleSwitchChange = (e) => {
    console.log("handle switch change called : ", e);

    const { name, value, id } = e.target;
    console.log("Captured edit element name : ", name);
    console.log("Captured edit element value : ", value);
    console.log("Captured edit element id : ", id);

    if (id) {
      var currentRowId = id.substring(id.lastIndexOf("-") + 1);
      var nameOfElement = id.substring(0, id.lastIndexOf("-"));
      console.log("Name of element : ", nameOfElement);
      console.log("Current row id : ", currentRowId);

      setSelectedId(currentRowId);

      setShowDialog(true);
    }
  };

  return (
    <Container>
      <Sidebar2 />
      <div className="container">
        
        <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <h4>Services</h4>
            </Col>
            <Col xs={6} md={6} sm={6} lg={6}>
              <Button
                variant="warning"
                id="add-btn-id"
                name="add-btn-name"
                className=" float-end"
                onClick={(e) => handleAdd(e)}
              >
                New Service
              </Button>
            </Col>
          </Row>
          <hr align="center" />
         
       
        <div className="ui-divider"></div>

       <Table striped bordered hover>
        
          <thead>
            <tr className="table-primary"key={`key-tr-head`}>
              <th className="table-row-heading" key={`key-th-head-sno`}>S.No</th>
              <th className="table-row-heading" key={`key-th-head-service`}>Service</th>
              <th className="table-row-heading" key={`key-th-head-active`}>Active</th>
              <th className="table-row-heading"key={`key-th-actions`}>...</th>
            </tr>
          </thead>
          <tbody>
            {serviceList.map((service, idx) => (
              <tr key={`tr-key-${idx}`}>
                <td key={`key-sno-${idx}`}>{idx + 1}</td>
                <td key={`key-name-${idx}`}>{service.service_name}</td>
                <td key={`key-switch-${idx}`}>
                  <Form.Check // prettier-ignore
                    key={`key-switch-ctrl-${idx}`}
                    type="switch"
                    id={`service-switch-${idx}`}
                    checked={service.active}
                    onChange={handleSwitchChange}
                  />
                </td>
                <td key={`key-view-icon-${idx}`}>
                  <BsCardText
                    key={`view-icon-service-key-${idx}`}
                    size={28}
                    style={{ color: "#0D6EFD", cursor: "pointer" }}
                    onClick={(e) => handleView(e)}
                    name={`view-icon-service-name-${idx}`}
                    id={`view-icon-service-id-${idx}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <br></br>
        {show === true && (
          <Alert variant="danger" onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{showMsgTitle}</Alert.Heading>
            <p>{showMsg}</p>
          </Alert>
        )}
        <Modal
          show={showDialog}
          onHide={handleCloseDialog}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm enable/disable</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure want to confirm your actions?.</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSwitchAction}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListServices;
