import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
  Modal,
} from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
} from "react-icons/bs";

import { BsFillEnvelopePlusFill } from "react-icons/bs";

import axios from "axios";
import { client } from "../config/Config";

const ListPatients = (props) => {
  //function ListPatients(props) {
  const initialized = useRef(false);
  const navigate = useNavigate();

  var patientArr = [
    {
      patientId: 1,
      first_name: "araya",
      mobile_Primary: "9942410747",
      age: 35,
      gender: "Male",
      address1: "2/262, ramana nagar",
      address2: "Avinashi",
      city: "Tiruppur",
    },
    {
      patientId: 2,
      first_name: "Ilango",
      mobile_Primary: "8870886958",
      age: 35,
      gender: "Male",
      address1: "Soolai",
      address2: "Avinashi",
      city: "Tiruppur",
    },
    {
      patientId: 3,
      first_name: "Celcia",
      mobile_Primary: "9787453372",
      age: 23,
      gender: "Female",
      address1: "Sevur",
      address2: "Avinashi",
      city: "Tiruppur",
    },
  ];

  let intialValues = {
    first_name: "",
    mobile_number: "",
  };

  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  var validateErr = false;

  const [isSubmit, setIsSubmit] = useState(false);
  const [patientsList, setPatientsList] = useState(patientArr);
  const [patientsListOrig, setPatientsListOrig] = useState(patientArr);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);
  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const validate = (values) => {
    console.log("validateErr before start processing : ", validateErr);
    console.log("Validate called ...", values);
    const errors = {};
    const regex = /^[^\\$@]+@[^\\$@]+\\.[^\\$@]{2,}$/i;

    let validName = true;
    if (!values.first_name) {
      errors.first_name = "Patient name is required";
      validateErr = true;

      validName = false;
    }

    let validNumber = true;
    if (values.mobile_number && values.mobile_number.length != 10) {
      errors.mobile_number = "Mobile number should be 10 digits";
      validateErr = true;
      validNumber = false;
      //setValidateError(true);
    }

    if (validNumber === true || validName === true) {
      validateErr = false;
      errors.mobile_number = "";
      errors.first_name = "";
    }
    console.log("VALIDATE ERROR inside validate func : ", validateErr);
    return errors;
  };

  //navigate("/addUpdatePatient");
  const [patientList, setPatientList] = useState(patientArr);
  const [toRender, setToRender] = useState(false);

  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Please login again");
  const [showMsgTitle, setShowMsgTitle] = useState(
    "Invalid authentication Session!"
  );
  const updatePatientStatus = async (selectedId) => {
    var selectedObj = patientsList[selectedId];
    let reqStatusUpdatePayload = {
      patient_id: selectedObj.patient_id,
      active: !selectedObj.active,
    };

    var headers = client.headers;
    console.log("payload:", reqStatusUpdatePayload);

    try {
      var aIVal = localStorage.getItem("_aI");
      var headers = {
        ...client.headers,
        Authorization: `Bearer ${aIVal}`
      }
      var res = await axios.post(
        client.domain + "/dcl/activatePatient",
        reqStatusUpdatePayload,
        { headers },
        { withCredentials: false }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        //console.log("Response message from server : ", res.data.detail.res_data);
        //console.log("Response message from server userInfo : ", res.data.detail.res_data.user_info);
        //console.log("Response message from server aI : ", res.data.detail.res_data.aI);
        // Remove login session from localstorage
        //localStorage.removeItem("username");
        //localStorage.removeItem("_aI")
        //navigate("/auth");

        var itemSelectedUpdate = patientsList[selectedId];
        itemSelectedUpdate.active = !itemSelectedUpdate.active;
        console.log(
          "<<<<<<<<<<<<<<<<< ITEM SELECTED MODIFIED ********** ",
          itemSelectedUpdate
        );

        const newitems = patientsList.slice();
        newitems[selectedId] = itemSelectedUpdate;
        setPatientsList(newitems);

        setShowDialog(false);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          //setShowMsg(error.response.data.detail.res_data);
          //setShowMsgTitle("LOGIN FAILED!");
          //setShow(true);
        }
      }
    }
    setShowDialog(false);
  };
  const handleSwitchAction = () => {
    console.log("user confirmed to enable or disable ...");
    console.log("user selected id for action is : ", selectedId);

    // call update API
    updatePatientStatus(selectedId);
  };

  //navigate("/addUpdatePatient");

  const getPatientLists = async () => {
    console.log("Loading initial patient list from server ....");
    try {
      var headers = client.headers;

      var res = await axios.get(
        client.domain + "/dcl/getAllPatients",
        { headers },
        { withCredentials: false }
      );
      console.log("Response from server patient : ", res.data.detail);

      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server patient : ",
          res.data.detail.data
        );
        setPatientList(res.data.detail.data);
        setPatientsListOrig(res.data.detail.data);
        setToRender(true);
        //navigate("/dashboard");
        //setDcReportData(res.data.detail.res_data);
        //setToRender(true);
      }
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
        setShowMsg("Please try after sometime");
        setShowMsgTitle("Network Issue!");
        setShow(true);
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg("Please try again ...");
          setShowMsgTitle("Unable to process your request!");
          setShow(true);
          //navigate("/auth");
        }
      }
    }
  };

  function handleDeletePatient(e) {
    e.preventDefault();
    console.log("Handle Delete patient called : ", e);

    const { name, value, id } = e.target;
    console.log("Captured delete element name : ", name);
    console.log("Captured delete element value : ", value);
    console.log("Captured delete element id : ", id);
  }

  const handleEditPatient = (e) => {
    e.preventDefault();
    console.log("Handle Edit patient called : ", e);

    const { name, value, id } = e.target;
    console.log("Captured edit element name : ", name);
    console.log("Captured edit element value : ", value);
    console.log("Captured edit element id : ", id);

    if (id) {
      var currentRowId = id.substring(id.lastIndexOf("-") + 1);
      var nameOfElement = id.substring(0, id.lastIndexOf("-"));
      console.log("Name of element : ", nameOfElement);
      console.log("Current row id : ", currentRowId);

      console.log("Currently selected row value : ", patientList[currentRowId]);
      navigate("/addUpdatePatient", {
        state: { passed_element: patientList[currentRowId], action: "view" },
      });
    }
  };

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListPatients called ##########################"
      );

      getPatientLists();
      initialized.current = true;
    }
  }, []);

  const handleAdd = async (e) => {
    console.log("handle add patients called : ", e);

    navigate("/addUpdatePatient", {
      state: { passed_element: [], action: "add" },
    });
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    console.log("handle search called : ", e);
    validateErr = false;
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    console.log(
      "^^^^^^^^^^^^^^^^^^^^ VALIDATE ERROR &&&&&&&&&&&&&&&&&&&&&& 2 : ",
      validateErr
    );
    if (validateErr === false) {
      console.log("FORM VALUES at handleSubmit: ", formValues);
    }

    /*
    const searchedElement = mobile_number.find(
      (ele) => ele.value === state.passed_element.mobile_number
    );
    */
    if (formValues.first_name) {
      const searchedElement = patientList.find(
        (ele) => ele.first_name === formValues.first_name
      );
      console.log("searched element : ", searchedElement);

      setPatientList([searchedElement]);
    }

    if (formValues.mobile_number) {
      const searchedElement2 = patientList.find(
        (ele) => ele.mobile_primary === formValues.mobile_number
      );
      console.log("searched element : ", searchedElement2);
      setPatientList([searchedElement2]);
    }
  };

  const handleClearSearch = (e) => {
    //e.preventDefault();
    console.log("handle  clear search called : ", e);
    //console.log("setPatientsListOrig : ", patientsListOrig);
    //setPatientList(patientArr);
    //console.log("setPatientlist : ", patientsList);
    setPatientList(patientsListOrig);

    setFormValues(intialValues);
  };

  const handleValueChange = (e) => {
    //e.preventDefault();

    console.log("Clicked value : ", e);
    const { name, value } = e.target;
    console.log("Captured input element name : ", name);
    console.log("Captured input element value : ", value);
    setFormValues({ ...formValues, [name]: value });

    //console.log("Clicked data : ", data);
  };

  return (
    <Container>
      <Sidebar2 />
      <Form onSubmit={handleSearch}>
        <Row>
          <Col xs={2} md={2} sm={2} lg={2}>
            <h4 className="text-left" style={{ color: "#4d4d4d" }}>
              Patients
            </h4>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2}>
          <Form.Group className="mb-3">
                 
            <Form.Control
            
              type="number"
              name="mobile_number"
              className="mobNum"
              id="search-mobile-id"
              placeholder="Mobile to search"
              maxlength="10"
              value={formValues.mobile_number}
              onChange={(e) => handleValueChange(e)}
            />
            </Form.Group>

            <p className="error-msg">{formErrors.mobile_number}</p>
          </Col>

          <Col xs={2} md={2} sm={2} lg={2}>
          <Form.Group className="mb-3">     
            <Form.Control
           
              type="text"
              name="first_name"
              id="search-name-id"
              placeholder="Name to search"
              onkeyup="this.value=this.value.replace(/[^A-z]/g,'');"
              value={formValues.first_name}
              onChange={(e) => handleValueChange(e)}
            />
            </Form.Group>
            <p className="error-msg">{formErrors.first_name}</p>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2}>
            <Button
              variant="warning"
              id="btn-search-id"
              name="btn-search-name"
              type="submit"
            >
              Search
            </Button>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2}>
            <Button
              variant="secondary"
              id="btn-search-id"
              name="btn-search-name"
              onClick={handleClearSearch}
            >
              Clear Search
            </Button>
          </Col>
          <Col
            xs={2}
            md={2}
            sm={2}
            lg={2}
            className="float-end justify-content-end"
          >
            <Button
              variant="warning"
              id="addUpdateId"
              className="float-end"
              onClick={(e) => handleAdd(e)}
            >
              New Patient
            </Button>
          </Col>
        </Row>
      </Form>
      <Row style={{ height: props.height }}>
        <hr />
      </Row>
      <br></br>
      <table className="table table-bordered table-striped">
        <thead>
          <tr className="table-primary">
            <th  className="table-row-heading" key={0}>
              S.No
            </th>
            <th className="table-row-heading">Name</th>
            <th className="table-row-heading">Mobile</th>
            <th className="table-row-heading">Age</th>
            <th className="table-row-heading">Address</th>
            <th className="table-row-heading">Details</th>
          </tr>
        </thead>
        <tbody>
          {patientList.map((patient, idx) => (
            <tr>
              <td key={idx}>{idx + 1}</td>
              <td>{patient.patient_name}</td>
              <td>{patient.mobile_primary}</td>
              <td>{patient.age}</td>
              <td>{patient.address_line2}</td>
              <td>
                <BsCardText
                  key={`edit-btn-patient-key-${idx}`}
                  size={28}
                  style={{ color: "#0D6EFD", cursor: "pointer" }}
                  onClick={(e) => handleEditPatient(e)}
                  name={`edit-icon-patient-name-${idx}`}
                  id={`edit-icon-patient-id-${idx}`}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <br></br>
      {show === true && (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{showMsgTitle}</Alert.Heading>
          <p>{showMsg}</p>
        </Alert>
      )}
      <Modal
        show={showDialog}
        onHide={handleCloseDialog}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm enable/disable</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to confirm your actions?.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSwitchAction}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListPatients;
