import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button,Table } from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import {
  BsFillTrashFill,
  BsPencilSquare,
  BsFileRuled,
  BsCardText,
} from "react-icons/bs";

import { BsFillEnvelopePlusFill } from "react-icons/bs";

import axios from "axios";
import { client } from "../config/Config";

const ListUsers = (props) => {
  //function ListUsers(props) {
  const initialized = useRef(false);
  const navigate = useNavigate();

  var usersArr = [
    {
      id: 1,
      name: "Abi",
    },
    {
      id: 2,
      name: "John",
    },
    {
      id: 3,
      name: "Ram",
    },
  ];

  const [usersList, setUsersList] = useState(usersArr);
  //navigate("/addUpdateUser");

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect::ListUsers called ##########################"
      );

      initialized.current = true;
    }
  }, []);

  const handleAdd = async (e) => {
    console.log("handle add users called : ", e);
    navigate("/addupdateuser", { state: {} });
  };

  const handleView = async (e) => {
    console.log("handle edit users called : ", e);
    navigate("/addupdateuser", { state: {} });
  };

  const handleOnChange = async (e) => {
    console.log("handle on change called : ", e);
  };

  return (
    <Container>
      <Sidebar2 />
      <Container>
      <Row>
            <Col xs={6} sm={6} md={6} lg={6}>
              <h4>Users</h4>
            </Col>
            <Col xs={6} md={6} sm={6} lg={6}>
              <Button
                variant="warning"
                id="add-btn-id"
                name="add-btn-name"
                className=" float-end"
                onClick={(e) => handleAdd(e)}
              >
                New User
              </Button>
            </Col>
          </Row>
          <hr align="center" />
        <div className="ui-divider"></div>

        <Table striped bordered hover>
            <thead>
              <tr className="table-primary">
                <th className="table-row-heading">S.No</th>
                <th className="table-row-heading">User Name</th>
                <th className="table-row-heading">User Role</th>
                <th className="table-row-heading">Employee Name</th>
                <th className="table-row-heading">Contact No</th>
                <th className="table-row-heading">Email</th>
                <th className="table-row-heading">Lock Status</th>
                <th className="table-row-heading">Active</th>
                <th className="table-row-heading">Details</th>
              </tr>
            </thead>
            <tbody>
              {usersList.map((user, idx) => (
                <tr key={`user-table-row-id-${idx}`}>
                  <td key={`user-list-item-key-sno-${idx}`}>{idx + 1}</td>
                  <td key={`user-list-item-key-username${idx}`}>{user.username}</td>
                  <td key={`user-list-item-key-user_role_name${idx}`}>{user.user_role_name}</td>
                  <td key={`user-list-item-key-employee_name${idx}`}>{user.employee_name}</td>
                  <td key={`user-list-item-key-contact_no${idx}`}>{user.contact_no}</td>
                  <td key={`user-list-item-key-email${idx}`}>{user.email}</td>
                  <td key={`user-list-item-key-lock_status${idx}`}>{user.lock_status}</td>
                  <td>
                    <Form.Check // prettier-ignore
                      key={`user-switch-key-${idx}`}
                      type="switch"
                      id={`user-switch-${idx}`}
                      name={`user-switch-${idx}`}
                      onChange={handleOnChange}
                      checked={user.is_active}
                    ></Form.Check>
                  </td>
                  <td>
                    <BsCardText
                      key={`edit-btn-user-key-${idx}`}
                      size={28}
                      style={{ color: "#0D6EFD", cursor: "pointer" }}
                      onClick={(e) => handleView(e)}
                      name={`edit-icon-user-name-${idx}`}
                      id={`edit-icon-user-id-${idx}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
       
      </Container>
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default ListUsers;
