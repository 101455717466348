import React, { Fragment, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
//import Nav from 'react-bootstrap/Nav';
import { Nav, NavItem, Navbar, NavDropdown, Dropdown } from "react-bootstrap";
//import { Glyphicon } from '../GlyphiconToFa';
//import { RBGlyphicon } from 'react-bootstrap';
import { ArrowRight } from "react-bootstrap-icons";
import Offcanvas from "react-bootstrap/Offcanvas";

import styles from "../sideBarMenu.css";

//import { withRouter } from "react-router";
import "../Dashboard.css";

import logo_avn from "../assets/images/logo_avn.png";

import { useNavigate } from "react-router-dom";

import { client } from "../config/Config";
import axios from "axios";
import {BsFillPersonVcardFill, BsPower} from 'react-icons/bs'

const Sidebar2 = (props) => {
  const [show, setShow] = useState(false);
  const [showMsg, setShowMsg] = useState("Unable to login");
  const [showMsgTitle, setShowMsgTitle] = useState("Login FAILED!");

  const navigate = useNavigate();

  const handleLogoutClick = async () => {
    console.log("Logout button clicked ...");

    var aIFromLocalStorage = localStorage.getItem("_aI");
    var usernameFromLocalStorage = localStorage.getItem("username");
    console.log("username : ", usernameFromLocalStorage);
    console.log("aI : ", aIFromLocalStorage);

    console.log("username : ", usernameFromLocalStorage);
    console.log("jwt token  : ", aIFromLocalStorage);

    let reqLogoutPayload = {
      username: usernameFromLocalStorage,
      aI: aIFromLocalStorage,
    };

    var headers = client.headers;
    console.log("payload:", reqLogoutPayload);

    try {
      var res = await axios.post(
        client.domain + "/logout",
        reqLogoutPayload,
        { headers },
        { withCredentials: true }
      );
      //.then((res) => {
      //console.log("ADD New user res:", res);
      //localStorage.setItem('_token', res.data.res_data.token);
      console.log("Response from server : ", res.data.detail);
      if (res.data.detail.status === "SUCCESS") {
        console.log(
          "Response message from server : ",
          res.data.detail.res_data
        );
        console.log(
          "Response message from server userInfo : ",
          res.data.detail.res_data.user_info
        );
        //console.log("Response message from server aI : ", res.data.detail.res_data.aI);

        // Remove login session from localstorage
        localStorage.removeItem("username");
        localStorage.removeItem("_aI");

        navigate("/auth");
      }
      /*
          if (res.data.status === "SUCCESS" ) {
            this.setState ({
            tokenJwt: res.data.res_data.token,
            isLoaded: true,
            openOTP: true,
            })
              // this.props.history.push({pathname: client.taskViewPath, state:{pageList: this.state.page, orgName: this.state.orgName} });
          }
          else {
          }
        */
    } catch (error) {
      console.log("FULL ERROR : ", error);
      if (error.code === "ERR_NETWORK") {
        console.log(
          "Not able to reach target server please try after sometime"
        );
      } else {
        console.log("ERROR : ", error.response.data.detail);
        if (error.response.data.detail.status === "FAILED") {
          console.log(
            "Request failed due to ",
            error.response.data.detail.res_data
          );
          setShowMsg(error.response.data.detail.res_data);
          setShowMsgTitle("LOGIN FAILED!");
          setShow(true);
        }
      }
    }
  };
  const handleProfile = async(e) => {
    console.log("handle profile clicked : ", e);
  }

  //const handleSelect = (eventKey) => {
  //    alert(`selected ${eventKey}`);
  //}
  return (
    <>
      <Navbar
        key="sm"
        expand="sm"
        bg="secondary"
        fluid="true"
        className="bg-body-tertiary mb-3"
        style={{ backgroundColor: "black" }}
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={logo_avn} height="64" width="64" alt="Avinasoft" />
          </Navbar.Brand>

          <Navbar.Brand href="#">Bharath Scans</Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-sm`}
            aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                Bharath Scans
                <span style={{alignItems: 'right', textAlign: 'right', fontSize: '8px !important' }}>Log-In : Rayappan A</span>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/addUpdateServiceTransV3"> New Study</Nav.Link>
                <Nav.Link href="/addUpdateReceipt">Receipt</Nav.Link>
                <Nav.Link href="/addUpdateDispatch">Dispatch</Nav.Link>
                <NavDropdown
                  title="Reports"
                  id={`offcanvasNavbarDropdown-expand-sm`}
                >
                  <NavDropdown.Item href="/listServiceTrans">
                    Studies
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/reportServiveCharges">
                    Charges Collected
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title="Data"
                  id={`offcanvasNavbarDropdown-expand-sm`}
                >
                  <NavDropdown.Item href="/listPatients">
                    Patients
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/listDoctors">
                    Doctors
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title="Settings"
                  id={`offcanvasNavbarDropdown-expand-sm`}
                >
                  <NavDropdown.Item href="/listServices">
                    Services
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/listUsers">Users</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href= "/addUpdateProfile" onClick={(e)=> handleProfile(e)}><BsFillPersonVcardFill size={28}  style={{cursor: 'pointer', color: 'blue'}} /></Nav.Link>
                <Nav.Link onClick={()=> handleLogoutClick() }><BsPower size={28} style={{cursor: 'pointer', color: 'red'}}/></Nav.Link>
                {/*<Nav.Link onClick={() => handleLogoutClick()}>Logout</Nav.Link>*/}
              </Nav>
              {/*
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form>
                */}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};
//const Sidebar = withRouter(Side);
export default Sidebar2;
