import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { withRouter } from "react-router";
//import Sidebar from './Sidebar';
import Sidebar2 from "./Sidebar2";
import Footer from "./Footer";
//import Topmenu from "./Topmenu";
import OrderDetails from "./OrderDetails";
import "../Dashboard.css";

//import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";

import { useNavigate } from "react-router-dom";

import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import { client } from "../config/Config";

import { BsFillTrashFill, BsPencilSquare, BsFileRuled } from "react-icons/bs";

const Dashboard = (props) => {
  const initialized = useRef(false);
  var orderListLoad = [
    /*
        {order_id: 12, order_number:"ORD89494", customer_name: "Rockbreeze Technologies", order_date:"2023-06-12", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00},
        {order_id: 13, order_number:"ORD85995", customer_name: "SRT tranports", order_date:"2023-05-09", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00},
        {order_id: 14, order_number:"ORD67883", customer_name: "AVS Constructions", order_date:"2023-06-15", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00},
        {order_id: 15, order_number:"ORD65583", customer_name: "New way traders", order_date:"2023-07-11", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00},
        {order_id: 16, order_number:"ORD46378", customer_name: "Zero Plus promoters", order_date:"2023-07-01", order_price: 34000.00, gst: 3400.00, total_price: 38400.00, amount_paid: 30000.00, balance: 8400.00}
        */
  ];

  const handleClickAddInvoice = (e) => {
    e.preventDefault();
    console.log("Add or Update Invoice button clicked ...", e);
    //navigate("/addUpdatePatient");
  };

  useEffect(() => {
    if (!initialized.current) {
      console.log(
        "*************** useEffect called ##########################"
      );

      initialized.current = true;
    }
  }, []);

  return (
    <Container>
      <Sidebar2 />
      <div>
        <Row>
          <Col xs={12} md={12} sm={12}>
            <Button
              id="addUpdateId"
              className="float-end"
              onClick={(e) => handleClickAddInvoice(e)}
            >
              New Patient
            </Button>
          </Col>
        </Row>
        <br></br>
      </div>
    </Container>
  );
};
//const Dashboard = withRouter(Dash);
export default Dashboard;
